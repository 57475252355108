import React, { useState, useEffect, useRef } from 'react';
import { IoBagCheckOutline, IoClose } from 'react-icons/io5';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { BaseUrl } from '../../Config/config';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FaRupeeSign } from 'react-icons/fa';
import MainDrawer from './MainDrawer';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { cartcount, cartUpdateState } from '../../recoil/cart';

function Cart({ cartDrawerOpen, closeCartDrawer }) {
  const [items, setItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const navigate = useNavigate();
  const alertShownRef = useRef(false);
  const [Totalamount, setTotalamount] = useState(0);
  const [cartUpdateCount, setCartUpdateCount] = useRecoilState(cartUpdateState);
  const setcartcount = useSetRecoilState(cartcount);

  // Function to handle incrementing the quantity
  const handleIncrement = async (index) => {
    const updatedItems = [...items];
    const item = updatedItems[index];

    if (item) {
      console.log("item",item.quantity,"item.product.stock_quantity",item.product.total_stock);

      // Check if the current quantity is less than the available stock
      if (item.quantity < item.product.total_stock) {
        
        const newQuantity = item.quantity + 1;
        const success = await updateItemQuantity(item.product.cart_id, newQuantity);

        if (success) {
          updatedItems[index].quantity = newQuantity;
          setItems(updatedItems);
          localStorage.setItem('cartItems', JSON.stringify(updatedItems));
          updateTotalQuantity(updatedItems);
        }
      } else {
        // Notify the user if they try to exceed the stock
        toast.error(`Only ${item.product.total_stock} left in stock`);
      }
    }
  };

  // Function to handle decrementing the quantity
  const handleDecrement = async (index) => {
    const updatedItems = [...items];
    const item = updatedItems[index];

    if (item.quantity > 1) {
      const newQuantity = item.quantity - 1;
      const success = await updateItemQuantity(item.product.cart_id, newQuantity);

      if (success) {
        updatedItems[index].quantity = newQuantity;
        setItems(updatedItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));
        updateTotalQuantity(updatedItems);
      }
    }
  };

  // Fetch cart items on initial load
  useEffect(() => {
    const fetchCartItems = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch(`${BaseUrl}api/cart`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch items');
        }

        const data = await response.json();

        const updatedData = data.map((item, index) => ({
          ...item,
          SequentialId: index + 1,
        }));

        const total = updatedData.reduce((acc, el) => {
          const itemTotal = el.quantity * parseFloat(el.product.rate);
          return acc + itemTotal;
        }, 0);

        setTotalamount(total);
        setItems(updatedData);
        localStorage.setItem('cartItems', JSON.stringify(updatedData));
        updateTotalQuantity(updatedData);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchCartItems();
  }, [cartUpdateCount]);

  // Load stored cart items from localStorage
  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (storedCartItems) {
      setItems(storedCartItems);
      updateTotalQuantity(storedCartItems);
    }
  }, []);

  // Update the total quantity of items in the cart
  const updateTotalQuantity = (items) => {
    const total = items.reduce((sum, item) => sum + (item.quantity || 0), 0);
    setTotalQuantity(total);
  };

  // Function to update the item quantity via API
  const updateItemQuantity = async (id, quantity) => {
    const token = localStorage.getItem('token');
    try {
      await axios.patch(BaseUrl + `api/cart/${id}/increment`, { quantity }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCartUpdateCount((prev) => prev + 1);
      return true;
    } catch (err) {
      toast.error("Update failed");
      return false;
    }
  };
                                                                             
  // Function to handle deleting an item
  const handleDelete = async (cartId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(BaseUrl + `api/cart/${cartId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Item Deleted Successfully');
      setCartUpdateCount((prev) => prev + 1);
    } catch (err) {
      toast.error("Delete failed");
    }
  };

  // Proceed to checkout function
  const handleProceedToCheckout = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      if (!alertShownRef.current) {
        toast.error('You are not Logged in!');
        alertShownRef.current = true;
      }
      return;
    }

    if (items.length === 0) {
      toast.error('No product in the cart');
      return;
    }

    navigate('/checkout');
    closeCartDrawer();
  };

  return (
    <>
      <MainDrawer DrawerOpen={cartDrawerOpen} closeDrawer={closeCartDrawer}>
        <div className="flex flex-col w-full h-full justify-between items-middle bg-[#004d00] rounded cursor-pointer">
          <div className="w-full flex justify-between items-center relative px-5 py-4 border-b border-white">
            <h2 className="font-semibold text-lg m-0 text-white flex items-center">
              <span className="text-xl mr-2 mb-1 text-yellow-500">
                <IoBagCheckOutline />
              </span>
              Shopping Cart 
            </h2>
            <button
              onClick={closeCartDrawer}
              className="flex-colo p-2 font-medium text-yellow-500 bg-[#004d00] rounded-full hover:bg-[#002700] hover:text-white"
            >
              <IoClose />
            </button>
          </div>

          <div className="overflow-y-scroll flex-grow scrollbar-hide w-full max-h-full text-white">
            {items.length > 0 ? (
              items.map((item, index) => (
                <div key={index} className="grid grid-cols-8 gap-2 my-6 items-center">
                  <div className="col-span-2 bg-[#003300] rounded p-2 h-24">
                    <img
                      alt={item?.product?.item_name}
                      src={item?.product?.image_path ? BaseUrl+`${item?.product?.image_path}` : '/default-image.png'}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <div className="col-span-5 flex flex-col text-sm gap-2">
                    <h3 className="font-medium truncate">{item?.product?.item_name}</h3>
                    <h2 className="font-bold flex items-center text-yellow-500">
                      <FaRupeeSign /> <span className="ml-1">{item?.product?.rate}</span>
                    </h2>
                    <p>{item.ItemDescription}</p>
                    <div className="grid grid-cols-3 text-xs gap-1 border border-yellow-500 w-32">
                      <button
                        onClick={() => handleDecrement(index)}
                        disabled={item.quantity === 1}
                        className="flex-colo py-1 hover:bg-[#002700] hover:text-white"
                      >
                        <FiMinus />
                      </button>
                      <p className="flex-colo py-1">{item?.quantity}</p>
                      <button
                        onClick={() => handleIncrement(index)}
                        className="flex-colo py-1 hover:bg-[#002700] hover:text-white"
                      >
                        <FiPlus />
                      </button>
                    </div>
                  </div>
                  <div className="col-span-1 flex-colo">
                    <button
                      onClick={() => handleDelete(item?.product?.cart_id)}
                      className="flex-colo p-2 text-lg bg-yellow-500 rounded text-[#004d00] hover:bg-[#002700] hover:text-white"
                    >
                      <MdDelete />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center my-4">No items in the cart</p>
            )}
          </div>

          <button
            onClick={handleProceedToCheckout}
            className="w-full hover:bg-subMain transitions py-3 px-3 bg-[#003300] flex items-center justify-between rounded-b border-t border-white"
          >
            <div className="flex text-md font-medium text-white items-center gap-2">
              <FaRupeeSign /> {Totalamount.toFixed(0)}
            </div>
            <span className="text-sm text-yellow-500">Proceed to Checkout</span>
          </button>
        </div>
      </MainDrawer>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}

export default Cart;
