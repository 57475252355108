import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value); // Call the onSearch function passed as a prop
  };

  return (
    <div className="my-4 mx-auto max-w-3xl">
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={handleChange}
          placeholder="Search here.."
          className={`w-full px-4 py-3 pr-12 border rounded-lg shadow-sm focus:outline-none focus:ring-2 ${
            query
              ? 'bg-black text-white border-black focus:ring-black'
              : 'border-gray-300 focus:ring-blue-500'
          }`}
        />
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <FaSearch className={query ? 'text-white' : 'text-gray-400'} />
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
