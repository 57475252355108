import React from 'react';
import { Link } from 'react-router-dom';
import './Promos.css'; // Ensure the CSS file is updated with the new styles

function Promos() {
  const Prom = [
    {
      bg: 'bg-gradient-to-br from-[#ff7f50] via-[#ff6347] to-[#ff4500]', // Vibrant gradient color
      title: 'Express Delivery',
      img: 'promo1',
      icon: 'fa-solid fa-truck', // Using FontAwesome icon
      text: 'text-white', // White text color for better contrast
    },
    {
      bg: 'bg-gradient-to-br from-[#6a5acd] via-[#483d8b] to-[#4b0082]', // Another vibrant gradient
      title: 'Cash On Delivery',
      img: 'promo2',
      icon: 'fa-solid fa-wallet', // FontAwesome wallet icon
      text: 'text-white',
    },
    {
      bg: 'bg-gradient-to-br from-[#00fa9a] via-[#32cd32] to-[#228b22]', // Green gradient
      title: 'Ocean Of Gifts',
      img: 'promo3',
      icon: 'fa-solid fa-gift', // Gift icon
      text: 'text-white',
    },
  ];

  return (
    <div className="promos-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-20 px-4">
      {Prom.map((p, i) => (
        <div
          key={i + 1}
          className={`${p.bg} promo-card hover:scale-110 hover:shadow-2xl rounded-3xl grid grid-cols-2 gap-4 p-6 transition-transform duration-500 ease-in-out`}
        >
          <div className="promo-text-container py-10 pl-10 pr-2 transform transition-transform duration-500 ease-in-out hover:translate-x-4 hover:translate-y-4">
            <div className="flex items-center space-x-2">
              <i className={`${p.icon} text-4xl`}></i> {/* Icon */}
              <h2 className="promo-title font-extrabold text-2xl md:text-3xl text-white">
                {p.title}
              </h2>
            </div>
            <p className="promo-description mt-2 text-lg md:text-xl text-white">
              With selected items
            </p>
            <Link to="/shop" className={`promo-button bg-black ${p.text} mt-4 inline-block py-3 px-6 rounded-full text-lg md:text-xl transition-colors duration-300 ease-in-out hover:bg-white hover:text-black`}>
              SAVE NOW
            </Link>
          </div>
          <div className="promo-image-container w-full h-32 md:h-48">
            <img
              alt={p.title}
              src={`images/${p.img}.png`}
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Promos;
