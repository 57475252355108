import React, { useEffect, useState } from "react";
import { FaShoppingBag, FaRupeeSign } from "react-icons/fa";
import ProductModal1 from "../Modals/ProductModal1";
import { BaseUrl } from "../../Config/config";
import axios from "axios";
import toast from "react-hot-toast";

const PopularProduct = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showItems, setShowItems] = useState({});

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch(`${BaseUrl}api/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch items 4");
        }
        const data = await response.json();
        const updatedData = data.products.filter((el, i) => {
          return el.is_popular == 1;
        });
        setItems(updatedData);
        const initialShowItems = {};
        updatedData.forEach((item) => {
          initialShowItems[item.Id] = item.Status === 1;
        });
        setShowItems(initialShowItems);
      } catch (error) {
        setError(error);
        console.error("Error fetching items:", error);
      } finally {
        setLoading(false); // Ensure loading is set to false after data fetch
      }
    };

    fetchItems();
  }, []);

  const handleAddToCart = (productId) => {
    const token = localStorage.getItem("token");

    const requestBody = {
      product_id: productId,
      quantity: 1,
    };
    
    try {
      if (requestBody) {
        axios
          .post(BaseUrl + "api/cart", requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            toast.success("Item added to cart successfully");
            //   onClose();
          })
          .catch((err) => {
            toast.error("failed to add to cart");
          });
      }
    } catch (error) {
      // toast.error('Failed to add item to cart');
    }
 
  };

  const openModal = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (loading) {
    return <p className="text-center text-lg">Loading...</p>;
  }

  if (error) {
    return (
      <p className="text-center text-lg text-red-500">Error: {error.message}</p>
    );
  }

  const filteredItems = items.filter((item) => item.status === "active");

  return (
    <div className="shop-items mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-900">
        Popular Products{" "}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {filteredItems.slice(0, 8).map(
          (
            item // Limiting the number of displayed items to 8
          ) => (
            <div
              key={item.id} // Use item.Id as the key
              className="bg-white text-black shadow-md rounded-lg overflow-hidden transition-transform duration-300 hover:shadow-lg hover:scale-105"
              onClick={() => openModal(item)}
            >
              <div className="relative cursor-pointer w-full h-64 bg-gray-100">
                <img
                  src={BaseUrl + `${item.image_url}`} // Use item.ItemImageUrl for image source
                  alt={item.item_name}
                  className="w-full h-full object-cover rounded-t-lg"
                />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  {item.item_name}
                </h3>
                <div className="flex items-center mt-2">
                  <FaRupeeSign className="text-gray-800" />
                  <span className="text-lg font-bold text-gray-800 ml-1">
                    {item.rate}
                  </span>
                </div>
                <button
                  onClick={() => handleAddToCart(item)}
                  disabled={item.total_stock === 0} // Disable button if stock is zero
                  className={`px-4 py-2 bg-blue-500 text-white rounded ${
                    item.total_stock === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-blue-700"
                  }`}
                >
                  {item.total_stock === 0 ? "Currently Unavailable" : "Add to Cart"}
                </button>
              </div>
            </div>
          )
        )}
      </div>

      {/* ProductModal component */}
      {selectedProduct && (
        <ProductModal1
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          product={selectedProduct}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default PopularProduct;
