import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../Config/config';
import Login from '../../components/Modals/Login'; // Replace with the appropriate component for profile editing
import { Transition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserProfile = () => {
  let navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false); // Change Password modal state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    profile_pic: null,
  });
  const [previewPic, setPreviewPic] = useState(null); // State for profile picture preview
  const [passwordData, setPasswordData] = useState({
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setModalOpen(true); // Open login modal if token is not present
      setLoading(false);
      return;
    }

    axios.post(BaseUrl + 'api/my-profile', {}, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setUserDetails(response.data?.user);
        setFormData({
          name: response.data?.user.name || '',
          email: response.data?.user.email || '',
          phone_number: response.data?.user.phone_number || '',
          profile_pic: null,
        });
        setLoading(false);
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          navigate('/');
          localStorage.removeItem('token');
          toast.error('Please Login Again!');
        }
        console.error('error => ', error);
        setError('Error fetching user details');
        setLoading(false);
      });
  }, [navigate,editModalOpen]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeLoginModal = () => {
    setModalOpen(false);
  };

  const handleEditProfileClick = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const openChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  // const handleChange = (e) => {
  //   const { name, value, type, files } = e.target;
  //   if (type === 'file') {
  //     const file = files[0];
  //     setFormData(prevData => ({
  //       ...prevData,
  //       [name]: file,
  //     }));
  //     // Create a preview URL for the selected image
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreviewPic(reader.result);
  //     };
  //     if (file) {
  //       reader.readAsDataURL(file);
  //     }
  //   } else {
  //     setFormData(prevData => ({
  //       ...prevData,
  //       [name]: value,
  //     }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];

      // Check if a file is selected and validate the file size
      if (file) {
        // Check if the file size is greater than 2MB
        if (file.size > 2 * 1024 * 1024) {
          alert('Please select an image smaller than 2MB.');
          setEditModalOpen(false)
          return; // Stop processing if the file is too large
        }

        // Create a preview URL for the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewPic(reader.result);
        };
        reader.readAsDataURL(file);

        // Update form data with the selected file
        setFormData(prevData => ({
          ...prevData,
          [name]: file,
        }));
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You need to log in first!');
      setEditModalOpen(false);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone_number', formData.phone_number);
    if (formData.profile_pic) {
      formDataToSend.append('profile_pic', formData.profile_pic);
    }

    try {
      const response = await axios.post(BaseUrl + 'api/update-profile', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      // Update userDetails state with new data
      setUserDetails(prevDetails => ({
        ...prevDetails,
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        profile_pic: response.data.profile_pic, // Update with new profile picture URL if returned
      }));
      toast.success('Profile updated successfully!');
      setEditModalOpen(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      if (error?.response?.status === 401) {
        toast.error('Session expired. Please log in again.');
        localStorage.removeItem('token');
      } else {
        toast.error('Error updating profile');
      }
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You need to log in first!');
      setChangePasswordModalOpen(false);
      return;
    }

    if (passwordData.new_password !== passwordData.confirm_new_password) {
      toast.error('Passwords do not match!');
      return;
    }

    try {
      await axios.post(BaseUrl + 'api/change-password', {
        current_password: passwordData.current_password,
        new_password: passwordData.new_password,
        new_password_confirmation: passwordData.confirm_new_password,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Password changed successfully!');
      setChangePasswordModalOpen(false);
    } catch (error) {
      console.error('Error changing password:', error);
      if (error?.response?.status === 401) {
        toast.error('Session expired. Please log in again.');
        localStorage.removeItem('token');
      } else {
        toast.error('Error changing password');
      }
    }
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (loading) return <div className="text-center text-lg text-gray-700">Loading...</div>;

  if (error) return (
    <div className="text-center text-red-500">
      Error: {error}
    </div>
  );

  return (
    <div className="min-h-screen flex justify-center items-center bg-gradient-to-r from-green-500 to-white">
      <div className="w-full max-w-lg bg-gradient-to-r from-green-500 to-white rounded-lg shadow-lg p-8 border border-green-500">
        {userDetails && (
          <>
            <Transition in={true} timeout={300} mountOnEnter unmountOnExit>
              {state => (
                <div className={`transition-opacity duration-300 ${state === 'entered' ? 'opacity-100' : 'opacity-0'}`}>
                  <div className="flex flex-col items-center">
                    <h1 className="text-2xl font-bold mb-4 text-green-700">User Profile</h1>
                    <img
                      src={previewPic || (userDetails.profile_pic ? `${BaseUrl}${userDetails.profile_pic}` : '/default-profile-pic.png')}
                      alt="Profile"
                      className="w-24 h-24 rounded-full mb-4 border-4 border-green-500"
                    />
                    <p className="font-normal text-gray-700"><strong>Name:</strong> {userDetails.name}</p>
                    <p className="font-normal text-gray-700"><strong>Email:</strong> {userDetails.email}</p>
                    <p className="font-normal text-gray-700"><strong>Phone:</strong> {userDetails.phone_number}</p>
                    <div className="flex gap-4 mt-4">
                      <button
                        onClick={handleEditProfileClick}
                        className="bg-green-600 text-white py-2 px-4 rounded-md border border-green-600 hover:bg-green-500 transition"
                      >
                        Edit Profile
                      </button>
                      {/* <button
                        onClick={openChangePasswordModal}
                        className="bg-green-600 text-white py-2 px-4 rounded-md border border-green-600 hover:bg-green-500 transition"
                      >
                        Change Password
                      </button> */}
                    </div>
                  </div>
                </div>
              )}
            </Transition>
            {editModalOpen && (
              <div className="modal fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="modal-content bg-white p-6 rounded-lg w-full max-w-md">
                  <h2 className="text-xl font-bold mb-4">Edit Profile</h2>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={formData.phone_number}
                        disabled
                        // onChange={handleChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="profile_pic" className="block text-sm font-medium text-gray-700">Profile Picture</label>
                      <input
                        type="file"
                        id="profile_pic"
                        name="profile_pic"
                        accept="image/*"
                        onChange={handleChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                      {previewPic && <img src={previewPic} alt="Profile Preview" className="mt-4 w-24 h-24 rounded-full" />}
                    </div>
                    <div className="flex justify-end gap-4">
                      <button
                        type="button"
                        onClick={closeEditModal}
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-500"
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {changePasswordModalOpen && (
              <div className="modal fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="modal-content bg-white p-6 rounded-lg w-full max-w-md">
                  <h2 className="text-xl font-bold mb-4">Change Password</h2>
                  <form onSubmit={handlePasswordChange} className="space-y-4">
                    <div>
                      <label htmlFor="current_password" className="block text-sm font-medium text-gray-700">Current Password</label>
                      <input
                        type="password"
                        id="current_password"
                        name="current_password"
                        value={passwordData.current_password}
                        onChange={handlePasswordInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="new_password" className="block text-sm font-medium text-gray-700">New Password</label>
                      <input
                        type="password"
                        id="new_password"
                        name="new_password"
                        value={passwordData.new_password}
                        onChange={handlePasswordInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="confirm_new_password" className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                      <input
                        type="password"
                        id="confirm_new_password"
                        name="confirm_new_password"
                        value={passwordData.confirm_new_password}
                        onChange={handlePasswordInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div className="flex justify-end gap-4">
                      <button
                        type="button"
                        onClick={closeChangePasswordModal}
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-500"
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserProfile;

