import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiFillBackward } from 'react-icons/ai';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import CircleLoader from 'react-spinners/CircleLoader';
import toast from 'react-hot-toast';
import axios from 'axios';
import Layout from '../layout/Layout';
import OrderSummary from '../components/OrderSummary';
import Modal from './Modal';
import { BaseUrl } from '../Config/config';
import qr from '../images/qrcode.jpg'

const Checkout = () => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [user, setuser] = useState([])
  const [phone, setphone] = useState('')
  const navigate = useNavigate();
  const [product, setproduct] = useState([])
  const token = localStorage.getItem('token');
  useEffect(() => {
    const fetchAddresses = async () => {
      if (!token) {
        toast.error('You are not logged in!');
        return;
      }

      try {
        const response = await fetch(`${BaseUrl}api/addresses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch addresses');
        const data = await response.json();
        setAddresses(data);
      } catch (error) {
        console.error('Error fetching addresses:', error);
        toast.error('Failed to fetch addresses');
      }
    };

   
    

    const fetchCartItems = async () => {
      if (!token) {
        toast.error('You are not logged in!');
        return;
      }

      try {
        const response = await fetch(`${BaseUrl}api/cart`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch cart items');
        const data = await response.json();
        setCartItems(data.map((item, index) => ({ ...item, SequentialId: index + 1 })));
      } catch (error) {
        console.error('Error fetching cart items:', error);
        toast.error('Failed to fetch cart items');
      }
    };

    const fetchCategories = async () => {
      // try {
      //   const response = await fetch(`${BaseUrl}api/Master/GetItemMaster?type=0`, {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   });

      //   if (!response.ok) throw new Error('Failed to fetch categories');
      //   const data = await response.json();
      //   setCategories(data);
      // } catch (error) {
      //   console.error('Error fetching categories:', error);
      //   toast.error('Failed to fetch categories');
      // }
    };

    fetchAddresses();
    fetchCartItems();
    fetchCategories();
  }, [token]);

  const handleAddressChange = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    const { id, street } = selectedValue;
    setSelectedAddressId(selectedValue);
  };
 
  useEffect(() => {
    axios.post(BaseUrl + 'api/my-profile', {}, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((res)=>{
      setuser(res.data.user);
      
    })
    axios.get(BaseUrl + 'api/cart', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
   .then((res)=>{
      const updatedData = res.data[0]
      setproduct(res.data);
      
   }).catch((err)=>{
    console.log(err);
    
   })

      
      
     

      //   const total = updatedData.reduce((acc, el) => {
      //     const itemTotal = el.quantity * parseFloat(el.product.rate);
      //     return acc + itemTotal;
      //   }, 0);


      //   setTotalamount(total)
      //   setItems(updatedData)
      //   localStorage.setItem('cartItems', JSON.stringify(updatedData));
      //   updateTotalQuantity(updatedData);
        
      // } catch (error) {
      //   console.error('Error fetching items:', error);
      //   toast.error('Failed to fetch items');
      // }
  }, [])
 

  const handleConfirm = (e) => {
    // e.preventDefault();

    // if (paymentMethod) {
      
    //   toast.success("Order confirmed")
    
    //   navigate('/shop')
    // }else{
    //   toast.error("please complete the Payment")
    // }
    
    if (!selectedAddressId || Object.keys(selectedAddressId).length === 0) {
      toast.error('Please select an address.');
      return;
    }
   
    if (!paymentMethod) {
      toast.error('Please select a payment method.');
      return;
    }

    if (paymentMethod === 'Card') {
      if (!cardNumber || !expiryDate || !cvv) {
        toast.error('Please provide all card details.');
        return;
      }
      const cardNumberRegex = /^\d{16}$/;
      const expiryDateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
      const cvvRegex = /^\d{3,4}$/;

      if (!cardNumberRegex.test(cardNumber.replace(/\s/g, ''))) {
        toast.error('Invalid card number.');
        return;
      }

      if (!expiryDateRegex.test(expiryDate)) {
        toast.error('Invalid expiry date.');
        return;
      }

      if (!cvvRegex.test(cvv)) {
        toast.error('Invalid CVV.');
        return;
      }
    }

    if (cartItems.length === 0) {
      toast.error('Your cart is empty!', { duration: 1800 });
      return;
    }
    setShowConfirmationModal(true);
  };


  

  const handleModalConfirm = async () => {
    
    setLoading(true);
    setShowConfirmationModal(false);
    
    const amount = cartItems.reduce((sum, item) => sum + (item.product.rate * item.quantity), 0);
    const taxAmount = cartItems.reduce((sum, item) => sum + (item.TaxAmt || 0), 0);
    const total = amount + 40 ;

  //   {
  //     "name": "John Doe",
  //     "phone_number": "1234567890",
  //     "address": "123 Main St",
  //     "total_price": 150.00,
  //     "items": [
  //         {
  //             "product_id": 1,
  //             "product_name": "Product A",
  //             "cost": 50.00,
  //             "quantity": 2
  //         },
  //         {
  //             "product_id": 2,
  //             "product_name": "Product B",
  //             "cost": 25.00,
  //             "quantity": 2
  //         }
  //     ]
  // }

  const items = cartItems.map((el,i)=>{
    return {
      product_id:el.product.id,
      product_name:el.product.item_name,
      cost:el.product.rate,
      quantity:el.quantity


    }
  })
  
    const data ={
      
      
        name: user.name,
        is_countersale:"0",
        mode:0,
        phone_number: user.phone_number,
        address: selectedAddressId?.street,
        total_price:total,
        items
    
    }

    try {
      await axios.post(`${BaseUrl}api/orders`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Your order has been confirmed!');
      setCartItems([]);
      setPaymentMethod('');
      setCardNumber('');
      setExpiryDate('');
      setCvv('');
      setTimeout(() => {
        setLoading(false);
        navigate('/table');
      }, 2000);
    } catch (error) {
      toast.error('Error confirming order. Please try again.');
      console.error('Error confirming order:', error);
      setLoading(false);
    }
  };

  const handleModalCancel = () => {
    setShowConfirmationModal(false);
  };

  const addAddress = () => {
    navigate('/Address');
  };

  return (
    <Layout>
      <div className="bg-gray-100 min-h-screen">
      {showConfirmationModal && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 shadow-lg rounded-lg w-80">
      <IoIosCheckmarkCircle className="text-green-600 text-4xl mx-auto mb-4" />
      <h2 className="text-xl font-semibold mb-4 text-center">Confirm Order</h2>
      <p className="mb-4 text-center">Are you sure you want to place this order?</p>
      <div className="flex justify-center">
        <button
          onClick={handleModalConfirm}
          className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-150 mr-2"
        >
          Confirm
        </button>
        <button
          onClick={handleModalCancel}
          className="bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-gray-700 transition duration-150"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

        <div className="container mx-auto px-4 py-8">
          {loading ? (
            <div className="flex justify-center items-center min-h-screen">
              <CircleLoader color="#36d7b7" size={100} />
            </div>
          ) : (
            <div className="lg:grid grid-cols-10 gap-10 items-start">
              <div className="col-span-6">
                <form className="flex flex-col gap-8" >
                  <div className="bg-white shadow-lg p-6 rounded-lg mt-6">
                    <h2 className="text-xl font-semibold mb-4">02. Address Selection</h2>
                    {addresses.length === 0 ? (
                      <div className="text-center">
                        <p className="mb-4 text-lg font-semibold text-red-600">No addresses found. Please add an address.</p>
                        <button
                          onClick={addAddress}
                          className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-150"
                        >
                          Add Address
                        </button>
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="address-select" className="block text-sm font-medium">Select Address:</label>
                        <select
                          id="address-select"
                          value={selectedAddressId.value}
                          onChange={handleAddressChange}
                          className="p-3 border border-gray-300 rounded-md w-full"
                        >
                          <option value="">Select Address</option>
                          {addresses.map((address) => (
                            <option key={address.id} value={JSON.stringify({ id: address.id, street: address.street })}>
                            {address.street} {address.city}
                          </option>
                          
                          
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="bg-white shadow-lg p-6 rounded-lg mt-6">
                    <h2 className="text-xl font-semibold mb-4">03. Payment Method</h2>
                    <div>
                      <label className="flex items-center mb-2">
                        <input
                          type="radio"
                          value="Card"
                          checked={paymentMethod === 'Card'}
                          onChange={() => setPaymentMethod('Card')}
                          className="mr-2"
                        />
                        Card
                      </label>
                      {paymentMethod === 'Card' && (
                        <div className="space-y-4">
                          <div>
                            <label htmlFor="card-number" className="block text-sm font-medium">Card Number:</label>
                            <input
                              type="text"
                              id="card-number"
                              value={cardNumber}
                              onChange={(e) => setCardNumber(e.target.value)}
                              placeholder="1234 5678 9012 3456"
                              className="p-3 border border-gray-300 rounded-md w-full"
                            />
                          </div>
                          <div className="flex gap-4">
                            <div className="w-1/2">
                              <label htmlFor="expiry-date" className="block text-sm font-medium">Expiry Date (MM/YY):</label>
                              <input
                                type="text"
                                id="expiry-date"
                                value={expiryDate}
                                onChange={(e) => setExpiryDate(e.target.value)}
                                placeholder="MM/YY"
                                className="p-3 border border-gray-300 rounded-md w-full"
                              />
                            </div>
                            <div className="w-1/2">
                              <label htmlFor="cvv" className="block text-sm font-medium">CVV:</label>
                              <input
                                type="text"
                                id="cvv"
                                value={cvv}
                                onChange={(e) => setCvv(e.target.value)}
                                placeholder="123"
                                className="p-3 border border-gray-300 rounded-md w-full"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <label className="flex items-center mt-4">
                        <input
                          type="radio"
                          value="Cash"
                          checked={paymentMethod === 'Cash'}
                          onChange={() => setPaymentMethod('Cash')}
                          className="mr-2"
                        />
                        Cash
                      </label>
                      <label className="flex items-center mt-4">
                        <input
                          type="radio"
                          value="UPI"
                          checked={paymentMethod === 'UPI'}
                          onChange={() => setPaymentMethod('UPI')}
                          className="mr-2"
                        />
                      UPI
                      </label>
                      {paymentMethod === 'UPI' && (
                        <div className="">
                         <img

                  alt="image"
                  src={qr}
                  style={{width:'200px',height:'200px'}}
                  className=""
                />
                        </div>
                      )}
                      <label className="flex items-center mt-2">
                        <input
                          type="radio"
                          value="Other"
                          checked={paymentMethod === 'Other'}
                          onChange={() => setPaymentMethod('Other')}
                          className="mr-2"
                        />
                        Other
                      </label>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-150"
                    onClick={handleConfirm}
                  >
                    place your order
                  </button>
                </form>
                
              </div>
              
              <div className="col-span-4">
                <OrderSummary cartItems={cartItems} />
              </div>
            </div>
          )}
        </div>
       

      </div>
    </Layout>
  );
};

export default Checkout;

