// utils/localStorage.js

/**
 * Save a value to localStorage.
 * @param {string} key - The key under which the value is stored.
 * @param {any} value - The value to store. It will be stringified.
 */
export function saveToLocalStorage(key, value) {
    try {
      const stringValue = JSON.stringify(value);
      localStorage.setItem(key, stringValue);
    } catch (error) {
      console.error(`Error saving to localStorage: ${error}`);
    }
  }
  
  /**
   * Retrieve a value from localStorage.
   * @param {string} key - The key for the value to retrieve.
   * @returns {any} The value retrieved, or null if not found or an error occurs.
   */
  export function getFromLocalStorage(key) {
    try {
      const stringValue = localStorage.getItem(key);
      return stringValue ? JSON.parse(stringValue) : null;
    } catch (error) {
      console.error(`Error retrieving from localStorage: ${error}`);
      return null;
    }
  }
  
  /**
   * Remove a value from localStorage.
   * @param {string} key - The key for the value to remove.
   */
  export function removeFromLocalStorage(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing from localStorage: ${error}`);
    }
  }
  
  /**
   * Clear all values from localStorage.
   */
  export function clearLocalStorage() {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(`Error clearing localStorage: ${error}`);
    }
  }
  