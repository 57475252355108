import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import toast from "react-hot-toast";
import { BaseUrl } from "../../Config/config";
import { FaRupeeSign, FaShoppingCart } from "react-icons/fa";
import ProductModal1 from "../Modals/ProductModal1";
import axios from "axios";
import Login from "../Modals/Login";
import { useSetRecoilState } from "recoil";
import { cartUpdateState } from "../../recoil/cart";

const CategoryProductsModal = ({
  modalOpen,
  onClose,
  products = [],
  addToCart,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const setCartUpdate = useSetRecoilState(cartUpdateState);


  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setSelectedProduct(null);
    setProductModalOpen(false);
  };
  const showAlert = (message) => {
    toast.custom((t) => (
      <div
        className={`bg-[#d7af37] text-white p-4 rounded shadow-md transition-all duration-300 ${t.visible ? 'animate-enter' : 'animate-leave'}`}
      >
        {message}
        
      </div>
    ));
  };

  const handleAddToCart = async (product) => {
    const token = localStorage.getItem("token");

    if (!token) {
      // setLoginModalOpen(true);
      showAlert("Please login");
      onClose()
      return;
    }

    const requestBody = {
      product_id: product.id,
      quantity: 1,
    };

    try {
      if (requestBody) {
        axios
          .post(BaseUrl + "api/cart", requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            toast.success("Item added to cart successfully");
            setCartUpdate((prev) => prev + 1);

            onClose();
          })
          .catch((err) => {
            toast.error("failed to add to cart");
          });
      }

      // if (response.ok) {
      //   const result = await response.json();
      //   toast.success('Item added to cart successfully');

      //   const cartItemKey = `cartItem_${product.Id}`;
      //   const existingItem = localStorage.getItem(cartItemKey);

      //   if (existingItem) {
      //     const existingItemDetails = JSON.parse(existingItem);
      //     existingItemDetails.Quantity += quantity;
      //     localStorage.setItem(cartItemKey, JSON.stringify(existingItemDetails));
      //   } else {
      //     const newItem = {
      //       Id: product.Id,
      //       ItemName: product.ItemName,
      //       ItemImage: product.ItemImage,
      //       Rate: product.Rate,
      //       Quantity: quantity,
      //       ItemDescription: product.ItemDescription,
      //       GST: product.GST,
      //       ItemType: product.ItemType,
      //       flashSalePrice: product.flashSalePrice,
      //       tag: product.tag,
      //     };
      //     localStorage.setItem(cartItemKey, JSON.stringify(newItem));
      //   }

      //   setProductModalOpen(false);
      // } else {
      //   toast.error('Failed to add item to cart');
      // }
    } catch (error) {
      toast.error("Failed to add item to cart");
    }
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={onClose} className="relative z-10">
        <Dialog.Overlay
          className="fixed inset-0 bg-black opacity-40"
          onClick={onClose}
        />

        <div
          className="fixed inset-0 flex items-center justify-center p-4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full mx-auto p-6 relative overflow-hidden">
            <button
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-3xl font-bold mb-6 text-[#003700]">
              Products   
            </h2>
            <div className="overflow-y-auto max-h-96">
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
                {products.length > 0 ? (
                  products.map((product) => (
                    <div
                      key={product.id} // Ensure `product.id` is correctly used here
                      onClick={() => handleProductClick(product)}
                      className="bg-white shadow-md rounded-xl overflow-hidden cursor-pointer hover:shadow-xl transition-shadow duration-300 transform hover:scale-105"
                    >
                      <img
                        src={BaseUrl + `${product.image_url}`}
                        alt={product.item_name}
                        className="w-full h-48 object-cover rounded-t-xl"
                      />
                      <div className="p-4">
                        <h3 className="text-lg font-semibold text-gray-800">
                          {product.item_name}
                        </h3>
                        <div className="flex items-center mt-2">
                          <FaRupeeSign className="text-gray-800" />
                          <h3 className="text-lg font-semibold text-gray-800 ml-1">
                            {product.rate}
                          </h3>
                        </div>
                        <p className="text-sm text-gray-600 mt-2">
                          {product.item_description}
                        </p>
                      </div>
                      <button
                        className={`group relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm font-bold rounded-b-lg transition-all duration-300 ${
                          product.total_stock <= 0
                            ? "bg-gray-400 cursor-not-allowed opacity-50"
                            : "bg-green-500 hover:bg-green-600 text-white"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddToCart(product);
                        }}
                        disabled={product.total_stock <= 0}
                      >
                        <span className="flex-1">Add to Cart</span>
                        <FaShoppingCart className="ml-2 h-5 w-5" />
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-600">No products available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Login modalOpen={loginModalOpen} setModalOpen={setLoginModalOpen} />
      {/* Render ProductModal1 if a product is selected */}
      {selectedProduct && (
        <ProductModal1
          modalOpen={productModalOpen}
          setModalOpen={setProductModalOpen}
          product={selectedProduct}
          onClose={handleCloseProductModal}
          addToCart={addToCart}
        />
      )}
    </>
  );
};

export default CategoryProductsModal;
