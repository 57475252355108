import React, { useState, useEffect } from "react";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Filter from "../components/Filter";
import Layout from "../layout/Layout";
import Promos from "../components/Promos";
import CategoriesShop from "../components/Home/Categories";
import PopularProduct from "../components/Home/PopularProduct";
import SearchBar from "./SearchBar";
import ProductModal1 from "../components/Modals/ProductModal1"; // Adjust the import path as needed
import { BaseUrl } from "../Config/config";
import Login from "../components/Modals/Login";
import { useSetRecoilState } from "recoil";
import { cartUpdateState } from "../recoil/cart";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function Shop() {
  const setCartUpdate = useSetRecoilState(cartUpdateState);
  const maxItemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(maxItemsPerPage);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null); // State for selected product
  const [hasShownNoProductsToast, setHasShownNoProductsToast] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const navigate = useNavigate()
  
  // Fetch products from the API

  const handleAddToCart = async (item) => {

    const token = localStorage.getItem("token");

    if (!token) {
      
      setLoginModalOpen(true);
      return;
    }

    const data = {
      product_id: item.id,
      quantity: 1,
    };

    if (data) {
      axios
        .post(BaseUrl + "api/cart", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          toast.success("Item added to cart successfully");
          setCartUpdate((prev) => prev + 1);
        })
        .catch((err) => {
          toast.error("failed to add to cart");
        });
    }
  };

  useEffect(() => {
    axios
      .get(BaseUrl + `api/products`)
      .then((res) => {
        const apiProducts = res?.data?.products;
        if (Array.isArray(apiProducts)) {
          setProducts(apiProducts);
        } else {
          console.error("Expected an array of products but got:", res?.data);
          setError("Invalid product data");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setError("Products not found");
        } else {
          setError("Error fetching products");
        }
        console.error("Error fetching products:", error);
      });
  }, []);
  // Handle loading more products
  const handleLoadMore = () => {
    setCurrentPage(currentPage + maxItemsPerPage);
  };
  // Handle filtering of products
  const handleFilter = (category) => {
    if (category === "All") {
      setFilteredProducts(null); // Show all products
    } else {
      const filtered = products.filter((product) =>
        product.categories.includes(category)
      );
      setFilteredProducts(filtered);
    }
  };
  // Handle product search
  const handleSearch = (query) => {
    if (query) {
      const filteredResults = products.filter(
        (product) =>
          product.item_name.toLowerCase().includes(query.toLowerCase()) &&
          product.Status !== 2 // Ensure that status is not 2
      );
      setSearchResults(filteredResults);
      if (filteredResults.length === 0 && !hasShownNoProductsToast) {
        toast.error("No products found");
        setHasShownNoProductsToast(true); // Set flag to prevent duplicate toasts
      } else if (filteredResults.length > 0) {
        setHasShownNoProductsToast(false);
      }
    } else {
      setSearchResults([]); 
      setHasShownNoProductsToast(false); 
    }
  };
  // Handle product click (open modal)
  // const handleProductClick = (product) => {
  //   setSelectedProduct(product);
  //   setModalOpen(true);
  // };
  // Close modal
  const handleCloseModal = () => {
    setSelectedProduct(null);
    setModalOpen(false);
  };
  // Determine the displayed products based on filters or search
  const displayedProducts =
    filteredProducts ||
    (Array.isArray(products) ? products.slice(0, currentPage) : []);
  const isEmpty = displayedProducts.length === 0 && searchResults.length === 0;
  return (
    <Layout>
      <SearchBar onSearch={handleSearch} />
      <div className="min-h-screen container mx-auto px-4 my-6">
        {isEmpty && (
          <div className="text-center text-gray-500 text-xl py-16">
            No products found
          </div>
        )}
        <CategoriesShop />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
          {(searchResults.length > 0 ? searchResults : displayedProducts).map(
            (product) => (
              <div
                key={product.id}
                className="bg-white shadow-lg rounded-xl overflow-hidden cursor-pointer"
                // onClick={() => handleProductClick(product)}
              >
                <img
                  // src={`https://init-pathrodo.jinskadamthodu.com/api/Master/LoadItemImage?ImageName=${product.image_path}`}
                  src={BaseUrl + `${product.image_url}`}
                  className="w-full h-48 object-cover"
                  alt={product.item_name}
                />
                {/* {BaseUrl+`${product.image_path}`} */}
                <div className="p-4">
                  <h3 className="text-lg font-semibold">{product.item_name}</h3>
                  <p className="text-sm">{product.description}</p>
                  <button className={`w-full py-3 px-4 border-2 font-bold rounded-md shadow-lg transition-all duration-300 ${product.total_stock <= 0 ? "opacity-50 cursor-not-allowed" : "hover:bg-green-700 bg-green-800 text-white"}`}
                    onClick={() => handleAddToCart(product)}
                    type="button"
                    disabled={product.total_stock <= 0}
                  >
                      {product.total_stock === 0 ? "Currently Unavailable" : "Add to Cart "}
                  </button>
                </div>
              </div>
            )
          )}
        </div>
        <Login modalOpen={loginModalOpen} setModalOpen={setLoginModalOpen} />

        {currentPage < products.length && (
          <div className="w-full flex justify-center my-12">
            {/* <button
              onClick={handleLoadMore}
              className="flex items-center justify-center gap-3 py-3 px-8 text-white bg-[#004a00] rounded-lg font-semibold hover:bg-[#003700] transition duration-300"
            >
              Load More
            </button> */}
          </div>
        )}
        <Filter total={products.length} onFilter={handleFilter} />
        <PopularProduct />
        <Promos />
      </div>
      {selectedProduct && (
        <ProductModal1
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          product={selectedProduct}
          isOpen={modalOpen}
          onClose={handleCloseModal}
        />
      )}
      {/* <ToastContainer /> */}
    </Layout>
  );
}
