import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import { BaseUrl } from "../../Config/config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { cartUpdateState } from "../../recoil/cart";
import toast from "react-hot-toast";

function DashboardItem() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const setCartUpdate = useSetRecoilState(cartUpdateState);
  const [Units, setUnits] = useState([])
  const [token, setToken] = useState("");


  const [newRow, setNewRow] = useState({
    ItemName: "",
    ItemDescription: "",
    GST: "",
    Cess: "",
    HSNCode: "",
    BatchNum: "",
    BarCode: "",
    CAT_Number: "",
    ItemCode: "",
    SerialNumber: "",
    ManufactureDate: "",
    ExpiryDate: "",
    Rate: "",
    Unit: "",
    ItemType: "",
    WarrantyPeriod: "",
    imageFile: null,
  });



  

  useEffect(() => {
    const tokenAdmin = localStorage.getItem("tokenadmin");
    if (!tokenAdmin) {
      navigate("/AdminLogin");
    } else {
      setToken(tokenAdmin);
    }

    const storedData = localStorage.getItem("tableData");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (Array.isArray(parsedData)) {
          setTableData(parsedData);
        }
      } catch (error) {
        console.error("Failed to parse stored data:", error);
      }
    }
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem("tableData", JSON.stringify(tableData));
  }, [tableData]);

  useEffect(() => {
    const fetchItemTypes = async () => {
      try {
        const response = await axios.get(`${BaseUrl}api/item-types`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setItemTypes(response.data); // Assuming response.data contains the list of item types
        }
      } catch (error) {
        console.error("Error fetching item types:", error);
      }
    };

    const fetchUnits = async () => {
      try {
        const response = await axios.get(`${BaseUrl}api/units`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          
          setUnits(response.data.units); // Assuming response.data contains the list of item types
        }
      } catch (error) {
        console.error("Error fetching Units:", error);
      }
    };

    if (token) {
      fetchItemTypes();
      fetchUnits()
    }
  }, [token]);

  useEffect(() => {
    const fetchItemTypes = async () => {
      try {
        const response = await fetch(`${BaseUrl}api/products`, {
          method: "POST", // Change to POST for fetching item types if that's the intended method
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch item types");
        }
        const data = await response.json();
        setItemTypes(data);
      } catch (error) {
        console.error("Error fetching item types:", error);
      }
    };

    if (token) {
      fetchItemTypes();
    }
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRow((prevRow) => ({
      ...prevRow,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setNewRow((prevRow) => ({
        ...prevRow,
        imageFile: file, // Assign the file object here
      }));
    }
  };

  const handleNewRowSubmit = (e) => {
    e.preventDefault();
    const newRowId =
      tableData.length === 0
        ? 0
        : Math.max(...tableData.map((row) => row.ID)) + 1;
    const rowToAdd = { ...newRow, ID: newRowId, Status: 1 };

    setTableData([...tableData, rowToAdd]);
    setNewRow({
      ItemName: "",
      ItemDescription: "",
      GST: "",
      Cess: 0,
      HSNCode: "",
      BatchNum: 0,
      BarCode: "",
      CAT_Number: 0,
      ItemCode: "",
      SerialNumber: "",
      ManufactureDate: "",
      ExpiryDate: "",
      Rate: "",
      Unit: "",
      ItemType: "",
      WarrantyPeriod: "",
      imageFile: null,
    });
  };

  const handleCreateOrUpdateData = async () => {
    const token = localStorage.getItem("tokenadmin");
    const updatedTableData = [];
    
      for (let row of tableData) {
        try {
          // {
          //   "item_name": "TOMATO 1 KG",
          //   "item_description": "1 KG Tomatos",
          //   "gst": "18.00",
          //   "hsn_code": "123456",
          //   "bar_code": "1234567890123",
          //   "item_code": "TOMA1KG",
          //   "serial_number": "SN123456",
          //   "manufacture_date": "2024-01-01",
          //   "expiry_date": "2025-01-01",
          //   "rate": "99.99",
          //   "unit_id": "1",
          //   "item_type_id": "1",
          //   "warranty_period": "2025-01-01",
          //   "image": "product_images/NzsuWDp0klcSDO91WFv7Zm4UHfYK6AesOa0sRM2J.png",
          //   "status": "active",
          // Construct the payload with ItemImage as an object
          const payload = {
            Id: row.ID || 0,
            item_name: row.ItemName || "",
            item_description: row.ItemDescription || "",
            gst: 18.00 || 0,
            Cess: 0,
            hsn_code: 'null' || "",
            BatchNum: 0,
            bar_code:  '' || "",
            CAT_Number: 0,
            item_code: row.ItemCode || "",
            serial_number: "12" || "",
            manufacture_date: row.ManufactureDate || "",
            expiry_date: row.ExpiryDate || "",
            item_code: "bhjj",
            rate: row.Rate || 0,
            Unit: row.Unit || 0,
            unit_id: 1 || 0, // Assuming this is the correct value for unit_id
            item_type_id: row.ItemType || 0,
            warranty_period: row.ExpiryDate || 0,
            status: "active",
            image:  row.imageFile || '',
            // item_type_id:1,
            is_popular:1
          };

        // Convert to FormData
        const formData = new FormData();

          formData.append("Id", payload.Id);
          formData.append("item_name", payload.item_name);
          formData.append("item_description", payload.item_description);
          formData.append("gst", payload.gst);
          formData.append("Cess", payload.Cess);
          formData.append("hsn_code", payload.hsn_code);
          formData.append("BatchNum", payload.BatchNum);
          formData.append("bar_code", payload.bar_code);
          formData.append("CAT_Number", payload.CAT_Number);
          formData.append("item_code", payload.item_code);
          formData.append("serial_number", payload.serial_number);
          formData.append("manufacture_date", payload.manufacture_date);
          formData.append("expiry_date", payload.expiry_date);
          formData.append("rate", payload.rate);
          formData.append("Unit", payload.Unit);
          formData.append("unit_id", payload.unit_id);
          formData.append("item_type_id", payload.item_type_id);
          formData.append("warranty_period", payload.warranty_period);
          formData.append("status", payload.status);
          formData.append("is_popular", payload.is_popular);

        // Handle the image field if it's a file
        if (payload.image.Image instanceof File) {
          formData.append("image", payload.image);
        } else {
          formData.append("image", payload.image);
        }


          axios
            .post(BaseUrl + "api/products", formData, {
              headers: {
                "Content-Type": "multipart/form-data", // Important to set the content type
              },
            })
            .then((response) => {
              toast.success('Product Addedd Successfully')
              setTableData([])
            })
            .catch((err) => {
              console.log(err);
            });

        // const response = await fetch(`${BaseUrl}api/products`, {
        //   method: 'POST', // Use POST or PUT depending on your API
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${token}`
        //   },
        //   body: JSON.stringify(payload),
        // });

        // if (!response.ok) {
        //   throw new Error(`Network response was not ok: ${response.statusText}`);
        // }

        // const result = await response.json();
        // updatedTableData.push(result);
        // toast.success(`Row with ID ${row.ID} saved successfully`);
      } catch (error) {
        console.log("errr", error);

        console.error("Error:", error);
        toast.error(`Failed to save row with ID ${row.ID}: ${error.message}`);
      }
    }

    // setTableData(updatedTableData);
  };

  useEffect(() => {
    axios.get(BaseUrl + "api/products").then((response) => {
      // setTableData(response.data.products)
    });
  }, []);

  const handleEditRow = (id, field, value) => {
    const updatedData = tableData.map((row) =>
      row.ID === id ? { ...row, [field]: value } : row
    );
    setTableData(updatedData);
  };

  const handleDeleteRow = (id) => {
    const updatedData = tableData.filter((row) => row.ID !== id);
    setTableData(updatedData);
    toast.success("Row deleted");
  };

  const handleToggleStatus = (id) => {
    const updatedData = tableData.map((row) =>
      row.ID === id ? { ...row, Status: row.Status === 0 ? 1 : 0 } : row
    );
    setTableData(updatedData);
  };

  return (
    <div className="container mx-auto p-6 min-h-screen text-black bg-gray-100">
      {/* <ToastContainer /> */}
      <h1 className="text-3xl font-semibold mb-6 text-center text-gray-800">
       Add Products 
      </h1>
      <form
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8"
        onSubmit={handleNewRowSubmit}>
          <div><label>Product Name <span className="text-red-500">*</span></label>
        <input
          type="text"
          name="ItemName"
          placeholder="Product Name "
          value={newRow.ItemName}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /></div>
        <div><label>Product  Description</label>
        <input
          type="text"
          name="ItemDescription"
          placeholder="Product  Description"
          value={newRow.ItemDescription}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /></div>
        {/* <input
          type="number"
          name="GST"
          placeholder="GST"
          value={newRow.GST}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /> */}
        {/* <input
          type="text"
          name="HSNCode"
          placeholder="HSN Code"
          value={newRow.HSNCode}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /> */}
        {/* <input
          type="text"
          name="BarCode"
          placeholder="Bar Code"
          value={newRow.BarCode}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /> */}
        <div><label>Product Code <span className="text-red-500">*</span></label>
        <input
          type="text"
          name="ItemCode"
          placeholder="Product Code"
          value={newRow.ItemCode}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /></div>
        <div><label>Manufacture Date <span className="text-red-500">*</span></label>
        <input
          type="date"
          name="ManufactureDate"
          placeholder="Manufacture Date"
          value={newRow.ManufactureDate}
          required

          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /></div>
        <div><label>Expiry Date <span className="text-red-500">*</span></label>
        <input
          type="date"
          name="ExpiryDate"
          placeholder="Expiry Date"
          value={newRow.ExpiryDate}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /></div>
        <div><label>Rate <span className="text-red-500">*</span></label>
        <input
          type="number"
          name="Rate"
          placeholder="Rate"
          value={newRow.Rate}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /></div>
        <div><label>Units <span className="text-red-500">*</span></label>
        {/* <input
          type="text"
          name="Unit"
          placeholder="Unit"
          value={newRow.Unit}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /> */}
         <select
          name="Unit"
          value={newRow.Unit}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2">
          <option value="">Select The Units</option>
          {Units?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
        </div>
        {/* <input
            type="text"
            name="ItemType"
            placeholder="Item Type"
            value={newRow.ItemType}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          /> */}
          <div><label>Category <span className="text-red-500">*</span></label>
        <select
          name="ItemType"
          value={newRow.ItemType}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2">
          <option value="">Select The Category</option>
          {itemTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.item_type}
            </option>
          ))}
        </select>
        </div>

        {/* <input
          type="text"
          name="WarrantyPeriod"
          placeholder="Warranty Period"
          value={newRow.WarrantyPeriod}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /> */}
          <div><label>Popular Product </label>
        
<div className="w-full p-2 border border-gray-300 rounded mb-2">
  <div className="flex items-center space-x-4">
    <label className="flex items-center">
      <input
        type="radio"
        name="PopularProduct"
        value="Yes"
        
        checked={newRow.PopularProduct === "Yes"}
        onChange={handleInputChange}
        className="mr-2"
      />
      Yes
    </label>
    <label className="flex items-center">
      <input
        type="radio"
        name="PopularProduct"
        value="No"
        checked={newRow.PopularProduct === "No"}
        onChange={handleInputChange}
        className="mr-2"
      />
      No
    </label>
  </div>
</div>
</div>








<div><label>Image<span className="text-red-500">*</span></label>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          required
          className="w-full p-2 border border-gray-300 rounded mb-2"
        /></div>
        <button
          type="submit"
          className="col-span-full bg-blue-500 text-white p-2 rounded mt-4">
          Add Row
        </button>
      </form>
      <table className="min-w-full mt-8 bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="border-b">ID</th>
            <th className="border-b">Product Name</th>
            <th className="border-b">Description</th>
            {/* <th className="border-b">GST</th> */}
            {/* <th className="border-b">HSN Code</th> */}
            {/* <th className="border-b">Bar Code</th> */}
            <th className="border-b">Product Code</th>
            <th className="border-b">Manufacture Date</th>
            <th className="border-b">Expiry Date</th>
            <th className="border-b">Rate</th>
            <th className="border-b">Unit</th>
            <th className="border-b">Product Type</th>
            {/* <th className="border-b">Warranty Period</th> */}
            <th className="border-b">Image</th>
            <th className="border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.ID}>
              <td className="border-b">{row.ID}</td>
              <td className="border-b">
                <input
                  type="text"
                  value={row.ItemName}
                  onChange={(e) =>
                    handleEditRow(row.ID, "ItemName", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              <td className="border-b">
                <input
                  type="text"
                  value={row.ItemDescription}
                  onChange={(e) =>
                    handleEditRow(row.ID, "ItemDescription", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              {/* <td className="border-b">
                <input
                  type="number"
                  value={row.GST}
                  onChange={(e) => handleEditRow(row.ID, "GST", e.target.value)}
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td> */}
              {/* <td className="border-b">
                <input
                  type="text"
                  value={row.HSNCode}
                  onChange={(e) =>
                    handleEditRow(row.ID, "HSNCode", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td> */}
              {/* <td className="border-b">
                <input
                  type="text"
                  value={row.BarCode}
                  onChange={(e) =>
                    handleEditRow(row.ID, "BarCode", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td> */}
              <td className="border-b">
                <input
                  type="text"
                  value={row.ItemCode}
                  onChange={(e) =>
                    handleEditRow(row.ID, "ItemCode", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              <td className="border-b">
                <input
                  type="date"
                  value={row.ManufactureDate}
                  onChange={(e) =>
                    handleEditRow(row.ID, "ManufactureDate", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              <td className="border-b">
                <input
                  type="date"
                  value={row.ExpiryDate}
                  onChange={(e) =>
                    handleEditRow(row.ID, "ExpiryDate", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              <td className="border-b">
                <input
                  type="number"
                  value={row.Rate}
                  onChange={(e) =>
                    handleEditRow(row.ID, "Rate", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              <td className="border-b">
                <input
                  type="text"
                  value={row.Unit}
                  onChange={(e) =>
                    handleEditRow(row.ID, "Unit", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              <td className="border-b">
                <input
                  type="text"
                  value={row.ItemType}
                  onChange={(e) =>
                    handleEditRow(row.ID, "ItemType", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td>
              {/* <td className="border-b">
                <input
                  type="text"
                  value={row.WarrantyPeriod}
                  onChange={(e) =>
                    handleEditRow(row.ID, "WarrantyPeriod", e.target.value)
                  }
                  className="w-full p-1 border border-gray-300 rounded"
                />
              </td> */}
              <td className="border-b">
                {row.imageFile && (
                  <img
                    src={`data:image/png;base64,${row.imageFile}`}
                    alt="Item"
                    className="w-16 h-16 object-cover"
                  />
                )}
              </td>
              <td className="border-b">
                <button
                  onClick={() => handleDeleteRow(row.ID)}
                  className="text-red-500 hover:text-red-700">
                  <MdDeleteForever size={20} />
                </button>
                <button
                  onClick={() => handleToggleStatus(row.ID)}
                  className="ml-2 text-blue-500 hover:text-blue-700">
                  {row.Status === 0 ? "Activate" : "Deactivate"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={handleCreateOrUpdateData}
        className="mt-4 bg-green-500 text-white p-2 rounded">
        Save All Changes
      </button>
    </div>
  );
}

export default DashboardItem;
