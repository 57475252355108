import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaBox,
  FaClock,
  FaShippingFast,
  FaCheckCircle,
  FaTimesCircle,
  FaChartLine,
  FaWarehouse,
  FaRegListAlt,
} from "react-icons/fa"; // Updated icons import
import { BaseUrl } from "../../Config/config";
import { format } from "date-fns";
import { MdFilterListAlt } from "react-icons/md";
import toast from "react-hot-toast";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function LiveOrders() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [FilteredData, setFilteredData] = useState([]);
  const [filter, setfilter] = useState(false);
  const today = new Date();
  const [Data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(today.toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(today.toISOString().split('T')[0]);
  
  const [status, setStatus] = useState(""); // For storing the selected status
  const handleclear = () => {
    setFromDate("");
    setToDate("");
    setStatus("");
    setFilteredData(Data);
  };
  const handleFilter = () => {
    console.log(Data);
    
    const filtered = Data.filter((item) => {
   
      const statusCheck = status ? item.order_status === status : true;

      return  statusCheck;
    });

    setFilteredData(filtered); // Update the filtered data
  };

  useEffect(() => {
    handleget()
  }, []);

  const handleget = ()=>{
    const tokenadmin = localStorage.getItem('tokenadmin');
  const data ={
    from_date: fromDate,
    to_date: toDate
}
    axios
      .post(BaseUrl + "api/all-orders",data, {
        headers: {
          Authorization: `Bearer ${tokenadmin}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (searchTerm) {
      const searchData = Data.filter((item) => {
        return (
          (item.name &&
            item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.item_type &&
            item.item_type.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.quantity &&
            item.quantity.toString().includes(searchTerm.toLowerCase())) ||
          (item.product_name &&
            item.product_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.address &&
            item.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.phone_number &&
            item.phone_number.toString().includes(searchTerm.toLowerCase())) ||
          (item.price && item.price.includes(searchTerm)) || // price as string
          (item.total_amount && item.total_amount.includes(searchTerm)) ||
          (item.code &&
            item.code.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.delivery_partner &&
            item.delivery_partner
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
        );
      });
      setFilteredData(searchData);
    } else {
      setFilteredData(Data);
    }
  }, [Data, searchTerm]);

  const handleStatusChange = (e, id) => {
    const tokenadmin = localStorage.getItem('tokenadmin');

    const data = {
      status: e.target.value,
    };
    axios
      .put(BaseUrl + `api/orders/${id}/admin-status`, data,{ headers: {
        Authorization: `Bearer ${tokenadmin}`,
      },})
      .then((res) => {
        toast.success("Status Updated Successfully");
        axios
        .get(BaseUrl + "api/all-orders", {
          headers: {
            Authorization: `Bearer ${tokenadmin}`,
          },
        })
          .then((response) => {
            setData(response.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        toast.error("Failed to update");
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const navigate = useNavigate()

  const handleclose =()=>{
    navigate(-1)
  }

  // const filteredData = Data.filter((item) => {
  //   // Filter by search term and filter status
  //   const matchesSearchTerm = item.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     item.phoneNumber.includes(searchTerm) ||
  //     item.productName.toLowerCase().includes(searchTerm.toLowerCase());

  //   const matchesStatus = filterStatus === '' || item.status === filterStatus;

  //   return matchesSearchTerm && matchesStatus;
  // });

const [grandtotal, setgrandtotal] = useState(0)
  useEffect(() => {
    const grandTotal = FilteredData.reduce((total, order) => {
      // Sum the total cost of each item in the current order
      const orderTotal = order.items.reduce((sum, item) => {
        return sum + (parseFloat(item.cost) * item.quantity);
      }, 0);
      
      // Add the order total to the grand total
      return total + orderTotal;
    }, 0);
    setgrandtotal(grandTotal)
   
   
  }, [FilteredData])
  
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <div className="justify-between flex">
      <h1 className="text-2xl font-semibold mb-4">Live Orders Overview</h1>
      <span className=""><MdCancel onClick={handleclose}/></span>
      
      </div>
      {/* Search and Filter Bar */}
      <div className="flex justify-between mb-4">
        {/* Search Input */}
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search..."
          className="p-2 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
        />
 <div className="flex">
  <label className="item-center p-2">From</label>
                <input
                  type="date"
                  className="flex-1 mr-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                  <label className="item-center p-2">To</label>
                <input
                  type="date"
                  className="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <button onClick={handleget} className="border bg-blue-500 text-white px-4 rounded p-2"> Get</button>
        {/* Filter Dropdown */}
        {/* <select
          value={filterStatus}
          onChange={handleFilterChange}
          className='p-2 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500'
        >
          <option value=''>All Statuses</option>
          <option value='Delivered'>Delivered</option>
          <option value='Shipped'>Shipped</option>
          <option value='Processing'>Processing</option>
        </select> */}
        <div>
          <MdFilterListAlt
            onClick={() => {
              setfilter(!filter);
            }}
          />
        </div>
      </div>
      <div>
        {filter ? (
          <div>
            <div className="p-2 flex flex-col border rounded">
              {/* <h4>Order date</h4>
              <div className="flex">
                <input
                  type="date"
                  className="flex-1 mr-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <input
                  type="date"
                  className="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div> */}

              <h4>Status</h4>
              <select
                className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="delivered">Delivered</option>
                <option value="Shipped">Shipped</option>
                <option value="Processing">Processing</option>
                <option value="pending">Pending</option>
                <option value="Cancelled">Cancelled</option>

              </select>
              <div className="flex justify-end gap-2">
                <button
                  onClick={handleclear}
                  leverage
                  className="mt-2 p-2 bg-yellow-600 text-white rounded "
                >
                  reset
                </button>
                <button
                  onClick={handleFilter}
                  leverage
                  className="mt-2 p-2 bg-blue-500 text-white rounded "
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* Table */}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg" style={{ paddingBottom: '50px' }}>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
               Order No
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                User name
              </th>
              <th scope="col" className="px-6 py-3">
                Phone Number
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>
              <th scope="col" className="px-6 py-3">
                Product Name
              </th>
              <th scope="col" className="px-6 py-3">
                Item Type
              </th>

              <th scope="col" className="px-6 py-3">
                Quantity
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>
              <th scope="col" className="px-6 py-3">
                Code
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              {/* <th scope="col" className="px-6 py-3">
                Delivery Partner
              </th> */}
              <th scope="col" className="px-6 py-3">
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody  >
          {FilteredData?.map((order, orderIndex) => (
  order.items.map((item, itemIndex) => (
    <tr
      key={`${orderIndex}-${itemIndex}`} // Unique key combining order and item index
      className={` ${order.is_countersale === 1 ? 'bg-green-200' : ''} border-t`}
    >
      <th
        scope="row"
        className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {orderIndex + 1}
      </th>
      <td className="px-4 py-4">
        {format(new Date(order.date_time), "dd-MM-yyyy")}
      </td>
      <td className="px-4 py-4">{order.name}</td>
      <td className="px-4 py-4">{order.phone_number}</td>
      <td className="px-4 py-4">{order.address}</td>
      <td className="px-4 py-4">{item.product_name}</td>
      <td className="px-4 py-4">{item.description}</td> {/* Adjust as needed */}
      <td className="px-4 py-4">{item.quantity}</td>
      <td className="px-4 py-4">{item.cost}</td>
      <td className="px-4 py-4">{item.product_id}</td> {/* Adjust as needed */}
      <td className="px-4 py-4">
        <select
          className="flex-1 w-[150px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={order.order_status} // Assuming you want to set the order status
          onChange={(e) => handleStatusChange(e, order.order_id)} // Use order_id here
        >
          <option value="">Change Status</option>
          <option value="delivered">Delivered</option>
          <option value="shipped">Shipped</option>
          <option value="processing">Processing</option>
          <option value="pending">Pending</option>
          <option value="Cancelled">Cancelled</option>
 
        </select>
      </td>
      {/* <td className="px-4 py-4">{order.delivery_partner}</td> Adjust if necessary */}
      <td className="px-4 py-4">{item.cost*item.quantity}</td>
    </tr>
  ))
))}

          </tbody>
          <div className="bg-gray-800 h-10 text-white fixed bottom-0 left-0 right-0 z-10">
  <div className="flex justify-between items-center h-full px-4">
    <div></div> {/* Empty div to keep the content aligned to the right */}
    <div className="flex items-center space-x-4" style={{paddingRight:'70px'}}>
      <b>Total</b>: <span className="font-semibold">{grandtotal}</span>
    </div>
  </div>
</div>

        </table>
      </div>
    </div>
  );
}

export default LiveOrders;
