import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';
import { BaseUrl } from '../Config/config';

// Example country and state data
const countries = [
  { value: 'IN', label: 'India' },
];

const states = {
  IN: [
    { value: 'AP', label: 'Andhra Pradesh' },
    { value: 'AR', label: 'Arunachal Pradesh' },
    { value: 'AS', label: 'Assam' },
    { value: 'BR', label: 'Bihar' },
    { value: 'CG', label: 'Chhattisgarh' },
    { value: 'GA', label: 'Goa' },
    { value: 'GJ', label: 'Gujarat' },
    { value: 'HR', label: 'Haryana' },
    { value: 'HP', label: 'Himachal Pradesh' },
    { value: 'JK', label: 'Jammu and Kashmir' },
    { value: 'JH', label: 'Jharkhand' },
    { value: 'KA', label: 'Karnataka' },
    { value: 'KL', label: 'Kerala' },
    { value: 'MP', label: 'Madhya Pradesh' },
    { value: 'MH', label: 'Maharashtra' },
    { value: 'MN', label: 'Manipur' },
    { value: 'ML', label: 'Meghalaya' },
    { value: 'MZ', label: 'Mizoram' },
    { value: 'NL', label: 'Nagaland' },
    { value: 'OD', label: 'Odisha' },
    { value: 'PB', label: 'Punjab' },
    { value: 'RJ', label: 'Rajasthan' },
    { value: 'SK', label: 'Sikkim' },
    { value: 'TN', label: 'Tamil Nadu' },
    { value: 'TG', label: 'Telangana' },
    { value: 'TR', label: 'Tripura' },
    { value: 'UP', label: 'Uttar Pradesh' },
    { value: 'UT', label: 'Uttarakhand' },
    { value: 'WB', label: 'West Bengal' },
  ],
};

const Address = () => {
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    country: 'IN', // Default to India
    postal_code: '',
    is_billing: false,
    is_shipping: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [stateOptions, setStateOptions] = useState(states.IN); // Default to Indian states
  const [addchange, setaddchange] = useState(0);
  
  const fetchAddresses = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in!');
      return;
    }

    try {
      const response = await fetch(BaseUrl + 'api/addresses', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Session expired! Please log in again.');
        localStorage.removeItem('token');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch addresses');
      }

      const data = await response.json();
      setAddresses(data);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  useEffect(() => {
    toast.dismiss();
    fetchAddresses();
  }, [addchange]);

  const handleDelete = async (addressId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in!');
      return;
    }

    try {
      const response = await fetch(BaseUrl + `api/addresses/${addressId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Session expired! Please log in again.');
        localStorage.removeItem('token');
        return;
      }

      if (!response.ok) {
        toast.error('Failed to delete address');
        return;
      }

      setAddresses((prevAddresses) =>
        prevAddresses.filter((address) => address.id !== addressId)
      );
      toast.success('Address deleted successfully');
    } catch (error) {
      console.error('Error deleting address:', error);
      toast.error('Failed to delete address');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      country: selectedOption.value,
      state: '', // Reset state when country changes
    }));
    setStateOptions(states[selectedOption.value] || []);
  };

  const handleStateChange = (selectedOption) => {
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      state: selectedOption.value,
    }));
  };

  const handleAddOrUpdateAddress = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in!');
      return;
    }

    if (!newAddress.street || !newAddress.city || !newAddress.state || !newAddress.country || !newAddress.postal_code) {
      toast.error('Please fill in all fields');
      return;
    }

    try {
      const method = isEditing ? 'POST' : 'POST';
      const endpoint = isEditing
        ? BaseUrl + `api/addresses/${editingAddressId}`
        : BaseUrl + 'api/addresses';

      const response = await fetch(endpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newAddress),
      });

      if (response.status === 401) {
        toast.error('Session expired! Please log in again.');
        localStorage.removeItem('token');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to add/update address');
      }

      const data = await response.json();
      if (isEditing) {
        setAddresses((prevAddresses) =>
          prevAddresses.map((address) => (address.id === data.id ? data : address))
        );
        toast.success('Address updated successfully');
        fetchAddresses();
      } else {
        setAddresses((prevAddresses) => [data, ...prevAddresses]);
        setaddchange(addchange + 1);
        toast.success('Address added successfully');
        fetchAddresses();
      }

      setNewAddress({
        street: '',
        city: '',
        state: '',
        country: 'IN', // Reset to India
        postal_code: '',
        is_billing: false,
        is_shipping: false,
      });
      setIsEditing(false);
      setShowForm(false);
    } catch (error) {
      console.error('Error adding/updating address:', error);
      toast.error('You can only add three addresses. Delete one to add a new address.');
    }
  };

  const handleEditAddress = (address) => {
    setNewAddress({
      street: address.street,
      city: address.city,
      state: address.state,
      country: address.country,
      postal_code: address.postal_code,
      is_billing: address.is_billing,
      is_shipping: address.is_shipping,
    });
    setEditingAddressId(address.id);
    setIsEditing(true);
    setShowForm(true);
    setStateOptions(states[address.country] || []); // Set states based on the country
  };

  return (
    <div className="container mx-auto p-8 bg-gray-50 min-h-screen">
      <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-900">Manage Your Addresses</h1>

      <button
        onClick={() => {
          setShowForm(true);
          setIsEditing(false);
          setStateOptions(states.IN); // Reset state options to Indian states
        }}
        className="bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg mb-6 shadow-md hover:bg-blue-700 transition-colors duration-300"
      >
        Add New Address
      </button>

      {showForm ? (
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
            {isEditing ? 'Edit Address' : 'Add New Address'}
          </h2>
          <form>
            <div className="mb-4">
              <label htmlFor="street" className="block text-lg font-medium text-gray-700">
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                value={newAddress.street}
                onChange={handleInputChange}
                className="mt-1 px-4 py-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="city" className="block text-lg font-medium text-gray-700">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={newAddress.city}
                onChange={handleInputChange}
                className="mt-1 px-4 py-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="country" className="block text-lg font-medium text-gray-700">
                Country
              </label>
              <Select
                id="country"
                options={countries}
                value={countries.find((c) => c.value === newAddress.country)}
                onChange={handleCountryChange}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="state" className="block text-lg font-medium text-gray-700">
                State
              </label>
              <Select
                id="state"
                options={stateOptions}
                value={stateOptions.find((s) => s.value === newAddress.state)}
                onChange={handleStateChange}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="postal_code" className="block text-lg font-medium text-gray-700">
                Postal Code
              </label>
              <input
                type="text"
                id="postal_code"
                name="postal_code"
                value={newAddress.postal_code}
                onChange={handleInputChange}
                className="mt-1 px-4 py-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="is_billing"
                name="is_billing"
                checked={newAddress.is_billing}
                onChange={handleInputChange}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="is_billing" className="ml-2 block text-lg font-medium text-gray-700">
                Billing Address
              </label>
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="is_shipping"
                name="is_shipping"
                checked={newAddress.is_shipping}
                onChange={handleInputChange}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="is_shipping" className="ml-2 block text-lg font-medium text-gray-700">
                Shipping Address
              </label>
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="button"
                onClick={handleAddOrUpdateAddress}
                className="bg-green-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-green-600 transition-colors duration-300"
              >
                {isEditing ? 'Update Address' : 'Add Address'}
              </button>
              <button
                type="button"
                onClick={() => setShowForm(false)}
                className="ml-4 bg-gray-300 text-gray-700 font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-gray-400 transition-colors duration-300"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : null}

      <div className="mt-8">
        {addresses.length > 0 ? (
          <ul className="space-y-4">
            {addresses.map((address) => (
              <li key={address.id} className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-2">
                  {address.street}, {address.city}, {address.state}, {address.postal_code}, {address.country}
                </h2>
                <p className="text-gray-700">
                  {address.is_billing && <span>Billing Address</span>} {address.is_shipping && <span>Shipping Address</span>}
                </p>
                <div className="flex justify-end mt-4 space-x-4">
                  <button
                    onClick={() => handleEditAddress(address)}
                    className="bg-yellow-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-yellow-600 transition-colors duration-300"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(address.id)}
                    className="bg-red-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-red-600 transition-colors duration-300"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-700 text-center">You have no addresses saved.</p>
        )}
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Address;
