import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Titles from '../Titles';
import { BsGridFill } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import CategoryProductsModal from './CategoryProductsModal';
import { BaseUrl } from '../../Config/config';
import SearchBar from '../../screens/SearchBar';

const CategoriesShop = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('token');

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(BaseUrl + 'api/item-types');
        const productsResponse = await axios.get(BaseUrl + 'api/products');

        setCategories(categoriesResponse.data);
        setProducts(productsResponse.data.products);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);
  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    const categoryProducts = products.filter(product => product?.item_type === category?.item_type);
    setCategoryProducts(categoryProducts);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCategory(null);
    setModalOpen(false);
  };

  if (loading) {
    return <p className="text-center text-lg">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-500">Error: {error.message}</p>;
  }

  // const filteredCategories = categories.filter(category => category.is_active === 1);

  const getSlidesPerView = () => {
    const categoryCount = filteredCategories.length;
    if (categoryCount <= 2) return 2;
    if (categoryCount <= 4) return 3;
    return 4;
  };

  const filteredCategories = categories
    .filter(category => category.is_active === 1)
    .filter(category => category.item_type.toLowerCase().includes(searchQuery.toLowerCase()));


  return (
    <div className="container mx-auto px-4 py-6 sm:px-6 lg:px-8">
      <Titles
        title={selectedCategory ? selectedCategory.ItemType : "Categories"}
        Icon={BsGridFill}
        titleColor="#15532e"
      />
           <SearchBar onSearch={handleSearch} />
      <div className="mt-4 sm:mt-6 lg:mt-8 flex flex-col items-center">
        {selectedCategory ? (
          <button
            onClick={handleCloseModal}
            className="bg-gradient-to-r from-green-600 to-green-800 transition duration-300 ease-in-out py-2 px-4 sm:py-3 sm:px-6 font-semibold text-xs sm:text-sm text-white shadow-lg rounded-md"
          >
            Back to Categories
          </button>
        ) : (
          <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            slidesPerView={getSlidesPerView()}
            spaceBetween={20}
            breakpoints={{
              640: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1024: { slidesPerView: getSlidesPerView() },
            }}
            className="py-4 sm:py-6 lg:py-8 w-full"
          >
            {filteredCategories.map((category, index) => {
              const isDarkYellow = index % 2 !== 0; // Check if the index is odd
              const iconColor = isDarkYellow ? '#FFD700' : '#15532e'; // Yellow for odd, green for even
              
              return (
                <SwiperSlide
                  key={category.Id}
                  onClick={() => handleCategoryClick(category)}
                  className="cursor-pointer flex justify-center items-center"
                >
                  <div
                    className={`flex flex-col items-center justify-center w-28 h-28 sm:w-32 sm:h-32 ${
                      isDarkYellow ? 'bg-gradient-to-r from-yellow-400 to-yellow-600' : 'bg-gradient-to-r from-green-600 to-green-800'
                    } rounded-3xl shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl`}
                  >
                    <div className="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow-md mb-2">
                      <BsGridFill className="text-3xl" style={{ color: iconColor }} />
                    </div>
                    <h3 className="mt-2 text-xs font-bold text-white text-center">
                      {category.item_type}
                    </h3>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
      {modalOpen && (
        <CategoryProductsModal
          modalOpen={modalOpen}
          onClose={handleCloseModal}
          products={categoryProducts}
        />
      )}
    </div>
  );
  };

export default CategoriesShop;
