import React from 'react';
import { useNavigate } from 'react-router-dom';

function AdminSettings() {
  const navigate = useNavigate();

  // Inline styles
  const containerStyle = {
    backgroundColor: '#004d00', // Dark green background
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    position: 'relative', // For positioning decorative shapes
  };

  const cardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    animation: 'fade-in 0.7s ease-in-out forwards',
  };

  const cardStyle = {
    backgroundColor: '#006400', // Slightly lighter green for the card
    color: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'background-color 0.3s, transform 0.3s',
    animation: 'fade-in-up 0.5s ease-in-out forwards',
  };

  const cardHoverStyle = {
    backgroundColor: '#004d00', // Darker green on hover
    transform: 'scale(1.05)',
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: '3rem', fontWeight: 'bold', color: '#FABC3F', marginBottom: '20px', textAlign: 'center' }}>Admin Settings</h1>
      <div style={cardContainerStyle}>
        <div
          style={cardStyle}
          onClick={() => navigate('/banner-settings')}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = cardHoverStyle.backgroundColor}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = cardStyle.backgroundColor}
        >
          <h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Banner Settings</h2>
        </div>
        <div
          style={cardStyle}
          onClick={() => navigate('/footer-settings')}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = cardHoverStyle.backgroundColor}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = cardStyle.backgroundColor}
        >
          <h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Footer Settings</h2>
        </div>
        <div
          style={cardStyle}
          onClick={() => navigate('/logo-settings')}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = cardHoverStyle.backgroundColor}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = cardStyle.backgroundColor}
        >
          <h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Logo Settings</h2>
        </div>
      </div>

      {/* Decorative Shapes */}
      <div style={{ position: 'absolute', inset: '0', pointerEvents: 'none', overflow: 'hidden' }}>
        <svg style={{ position: 'absolute', top: '0', left: '0', transform: 'translateX(16px) translateY(-20px)' }} width="300" height="300" fill="none">
          <circle cx="150" cy="150" r="150" fill="#A2CA71" fillOpacity="0.2" />
        </svg>
        <svg style={{ position: 'absolute', bottom: '0', right: '0', transform: 'translateX(-20px) translateY(24px)' }} width="400" height="400" fill="none">
          <circle cx="200" cy="200" r="200" fill="#387F39" fillOpacity="0.15" />
        </svg>
      </div>

      {/* Custom CSS for animations */}
      <style jsx>{`
        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
}

export default AdminSettings;
