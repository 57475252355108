import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io'; // Import back arrow icon
import { FaUser, FaBox, FaMapMarkerAlt } from 'react-icons/fa'; // Import icons for Profile, Orders, and Address

function Account() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-green-100 via-white to-green-100">
      {/* Back Button for Mobile View */}
      <button 
        className="fixed top-4 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg lg:hidden transition-transform transform hover:scale-110" 
        onClick={handleBack}
      >
        <IoMdArrowBack size={26} />
      </button>
      
      <header className="bg-gradient-to-r from-green-100 via-white to-green-100 text-green-800 shadow-lg">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold">My Account</h1> {/* Bold and large text */}
        </div>
      </header>
      
      <main className="flex-grow flex items-center justify-center p-6">
        <div className="w-full max-w-md">
          <div className="bg-white text-green-800 shadow-lg border border-yellow-400 rounded-lg overflow-hidden">
            <div className="p-6 bg-green-100 text-green-800">
              <h2 className="text-3xl font-semibold">Navigation</h2> {/* Large heading */}
            </div>
            <nav className="p-6 space-y-4">
              <NavLink
                to="/UserProfile"
                className={({ isActive }) =>
                  `flex items-center px-5 py-3 rounded-lg text-lg font-medium border border-green-800 transition-transform transform ${
                    isActive
                      ? 'bg-green-800 text-white shadow-md' // Dark green background for active state
                      : 'text-green-800 hover:bg-yellow-400 hover:text-green-800 hover:scale-105' // Yellow background with dark green text for hover
                  }`
                }
              >
                <FaUser className="mr-3 text-2xl" /> {/* User icon */}
                Profile
              </NavLink>
              <NavLink
                to="/table"
                className={({ isActive }) =>
                  `flex items-center px-5 py-3 rounded-lg text-lg font-medium border border-green-800 transition-transform transform ${
                    isActive
                      ? 'bg-green-800 text-white shadow-md' // Dark green background for active state
                      : 'text-green-800 hover:bg-yellow-400 hover:text-green-800 hover:scale-105' // Yellow background with dark green text for hover
                  }`
                }
              >
                <FaBox className="mr-3 text-2xl" /> {/* Box icon */}
                My Orders
              </NavLink>
              <NavLink
                to="/Address"
                className={({ isActive }) =>
                  `flex items-center px-5 py-3 rounded-lg text-lg font-medium border border-green-800 transition-transform transform ${
                    isActive
                      ? 'bg-green-800 text-white shadow-md' // Dark green background for active state
                      : 'text-green-800 hover:bg-yellow-400 hover:text-green-800 hover:scale-105' // Yellow background with dark green text for hover
                  }`
                }
              >
                <FaMapMarkerAlt className="mr-3 text-2xl" /> {/* Map marker icon */}
                Address
              </NavLink>
            </nav>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Account;
