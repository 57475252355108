// src/components/Titles.js
import React from 'react';

const Titles = ({ title, Icon }) => (
  <div className="title-container">
    {Icon && <Icon className="title-icon" />} {/* Render the icon if provided */}
    <h1 className="title-text">{title}</h1>
  </div>
);

export default Titles;
