import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../layout/navbar/Navbar';  
import Footer from '../layout/footer/Footer';

function ContactUs() {
  const contactData = [
    {
      id: 1,
      title: 'Email Us',
      info: 'We are here to answer your questions and help you get started.',
      icon: faEnvelope,
      contact: 'info@initsolutions.in',
      href: 'mailto:info@initsolutions.in',
      bg: 'bg-white text-green-900',
    },
    {
      id: 2,
      title: 'Call Us',
      info: 'Reach out to us by phone for immediate assistance.',
      icon: faPhone,
      contact: '+91 0484 359 9557',
      href: 'tel:+9104843599557',
      bg: 'bg-white text-green-900',
    },
    {
      id: 3,
      title: 'Visit Us',
      info: 'Visit our office for in-person support and consultations.',
      icon: faMapMarkerAlt,
      contact: 'Init Solutions',
      href: 'https://www.google.com/maps/search/46/2769-F,+Second+Floor,+SA+Plaza,+Vivekananda+Rd,+Vennala,+Kochi,+Kerala+682028',
      bg: 'bg-white text-green-900',
    },
  ];

  return (
    <>
      
      <Navbar />  
      
      {/* Main content */}
      <div className="min-h-screen bg-green-900 py-12 px-6">
        <div className="container mx-auto max-w-6xl">
          {/* Main Title with Animation */}
          <h2 className="text-5xl font-extrabold text-center text-white uppercase mb-16 animate-pulse">
            We Would Love To <span className="bg-gradient-to-r from-yellow-300 to-yellow-500 text-transparent bg-clip-text">Help You</span>
          </h2>

          {/* Container for Cards */}
          <div className="flex justify-center">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-5xl">
              {contactData.map((data) => (
                <div
                  key={data.id}
                  className={`${data.bg} p-8 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105`}
                >
                  <div className="flex items-center justify-center mb-4">
                    <FontAwesomeIcon icon={data.icon} className="text-5xl" />
                  </div>
                  <h5 className="text-2xl text-center font-bold mb-4">
                    {data.title}
                  </h5>
                  <p className="text-center text-lg mb-6">
                    <a href={data.href} className="hover:underline font-semibold">
                      {data.contact}
                    </a>
                  </p>
                  <p className="text-center opacity-90">{data.info}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Decorative Background Shapes */}
          <div className="absolute inset-0 pointer-events-none overflow-hidden">
            <svg className="absolute top-0 left-0 transform translate-x-16 -translate-y-20" width="300" height="300" fill="none">
              <circle cx="150" cy="150" r="150" fill="#A2CA71" fillOpacity="0.2" />
            </svg>
            <svg className="absolute bottom-0 right-0 transform -translate-x-20 translate-y-24" width="400" height="400" fill="none">
              <circle cx="200" cy="200" r="200" fill="#387F39" fillOpacity="0.15" />
            </svg>
          </div>
        </div>
      </div>
      <Footer />  

    </>
  );
}

export default ContactUs;
