import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { BaseUrl } from "../../../Config/config";
import { FaRegEdit } from "react-icons/fa";
import { FaRegCheckSquare } from "react-icons/fa";
import axios from "axios";
import toast from "react-hot-toast";
function AllProductShow() {
  const maxItemsPerPage = 10;
  const [items, setItems] = useState([]);
  const [products, setproducts] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [showItems, setShowItems] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayCount, setDisplayCount] = useState(maxItemsPerPage);
  const [Countersale, setCountersale] = useState(false);
  const [counterSales, setCounterSales] = useState({});
  const [editingItems, setEditingItems] = useState({});
  const [Units, setUnits] = useState([])
  const [updatedStockValues, setUpdatedStockValues] = useState({});
  const [editValues, setEditValues] = useState({
    Id: "",
    item_name: "",
    item_description: "",
    gst: "",
    Cess: 0,
    hsn_code: "",
    BatchNum: 0,
    bar_code: "",
    CAT_Number: 0,
    item_code: "",
    serial_number: "",
    manufacture_date: "",
    expiry_date: "",
    rate: "",
    Unit: "",
    unit_id: "",
    item_type_id: "",
    warranty_period: 0,
    status: "",
    image: "",
    is_popular: 1,
    stock_quantity: "",
  });

  useEffect(() => {
   console.log("`",editValues);
   
  }, [editValues])
  
  const handlecleareditvalue = () => {
    setEditValues({
      Id: "",
      item_name: "",
      item_description: "",
      gst: "",
      Cess: 0,
      hsn_code: "",
      BatchNum: 0,
      bar_code: "",
      CAT_Number: 0,
      item_code: "",
      serial_number: "",
      manufacture_date: "",
      expiry_date: "",
      rate: "",
      Unit: "",
      unit_id: "",
      item_type_id: "",
      warranty_period: 0,
      status: "",
      image: "",
      is_popular: 1,
      stock_quantity: 2,
    });
    setEditItem(null);
  };
  const [countersaledata, setcountersaledata] = useState({
    name: "",
    phone_number: "0000000000",
    address: "address",
    total_price: "",
    mode: 0,
    is_countersale:"1",
    items: [
      {
        product_id: "",
        product_name: "",
        cost: "",
        quantity: "",
      },
    ],
  });
  const [itemTypes, setItemTypes] = useState([]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setcountersaledata((prevRow) => ({
      ...prevRow,
      [name]: value,
    }));
  };
  useEffect(() => {
    fetchItems();
    fetchItemTypes();
    // fetchUnits()
  }, []);

  const handleupdate = () => {
    const token = localStorage.getItem("tokenadmin");

    if (editValues) {
      const formData = new FormData();

      // Append each field from editValues to the formData
      Object.keys(editValues).forEach((key) => {
        formData.append(key, editValues[key]);
      });

      // Make the request with FormData
      axios
        .post(BaseUrl + `api/product/${editValues.Id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Use this for file upload
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          handlecleareditvalue();
          toast.success("Product Updated Successfully");
          fetchItems();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  const reset = () =>{
    setcountersaledata({
      name: "",
      phone_number: "0000000000",
      address: "address",
      total_price: "",
      mode: 0,
      is_countersale: "1",
      items: [
        {
          product_id: "",
          product_name: "",
          cost: "",
          quantity: "",
        },
      ],
    });
  }

  const confirmcountersale = () => {
    const token = localStorage.getItem("tokenadmin");

    // Calculate total_price (quantity * cost for all items)
    const calculatedTotalPrice = countersaledata.items.reduce((total, item) => {
      const cost = parseFloat(item.cost); // Convert cost to number
      const quantity = parseInt(item.quantity) || 1; // Ensure quantity is a number, default to 1 if it's missing or invalid
      return total + cost * quantity;
    }, 0);

    // Update countersaledata with total_price before making the API call
    const updatedCountersaleData = {
      ...countersaledata,
      total_price: calculatedTotalPrice, // Set calculated total_price
    };

    console.log("updatedCountersaleData",updatedCountersaleData);
    

    if (token) {
      axios
        .post(`${BaseUrl}api/orders`, updatedCountersaleData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Order placed successfully", response);
          toast.success("Counter Sale Addedd Successfully");
          setCountersale(false);
          reset()
        })
        .catch((error) => {
          console.error("Error placing the order", error);
          toast.error(error.response.data.message)
          setCountersale(false);
          reset()
        });
    }
  };

  useEffect(() => {
    console.log("countersaledata", countersaledata);
  }, [countersaledata]);

  const handleProductChange = (e) => {
    const { value } = e.target;

    // Find the selected product based on the value (product ID)
    const selectedProduct = products.find(
      (product) => product.id === parseInt(value)
    );

    if (selectedProduct) {
      setcountersaledata((prevData) => ({
        ...prevData,
        items: [
          {
            product_id: selectedProduct.id,
            product_name: selectedProduct.item_name,
            cost: selectedProduct.rate,
            // Removed quantity update here
          },
        ],
      }));
    }
  };

  const handleToggleEdit = (itemId) => {
    setEditingItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId], // Toggle the editing state for the specific item
    }));
  };

  const handleStockChange = (itemId, newStockValue) => {
    setUpdatedStockValues((prev) => ({
      ...prev,
      [itemId]: newStockValue, // Update the stock value for the specific item
    }));
  };

  const handlestockchange = (id, updatedQuantity) => {
    const token = localStorage.getItem("tokenadmin");
    const data = {
      product_id: id,
      quantity: updatedQuantity, // Pass the actual edited quantity
    };

    axios
      .post(BaseUrl + "api/stocks", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success("Stock updated successfully");
        fetchItems();
      })
      .catch((err) => {
        console.log("Error updating stock:", err);
      });
  };
  // Function called when the check icon is clicked
  const handleStockSubmit = (itemId) => {
    const updatedStock = updatedStockValues[itemId]; // Get the updated stock value for this item

    if (updatedStock) {
      // Call the function to handle the stock change with the actual updated quantity
      handlestockchange(itemId, updatedStock);
    } else {
      console.log("No updated stock value provided.");
    }

    handleToggleEdit(itemId); // Disable editing after saving
  };

  const fetchItems = async () => {
    const token = localStorage.getItem("tokenadmin");
    try {
      const response = await fetch(`${BaseUrl}api/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch items");
      }
      const data = await response.json();
      const updatedData = data.products.map((item, index) => ({
        ...item,
        SequentialId: index,
        ItemImageUrl: item.ItemImage
          ? `${BaseUrl}api/products/LoadItemImage?ImageName=${item.ItemImage}`
          : null,
      }));
      setItems(updatedData);
      console.log("updatedData",updatedData);
      
      const initialShowItems = {};
      updatedData.forEach((item) => {
        initialShowItems[item.Id] = item.Status === 2;
      });
      setShowItems(initialShowItems);
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Failed to fetch items");
    }
  };

  const fetchItemTypes = async () => {
    const token = localStorage.getItem("tokenadmin");
    try {
      const response = await fetch(`${BaseUrl}api/item-types`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch item types");
      }
      const data = await response.json();
      setItemTypes(data);
    } catch (error) {
      console.error("Error fetching item types:", error);
      toast.error("Failed to fetch item types");
    }
  };

  const toggleItem = async (itemId) => {
    const newStatus = showItems[itemId] ? 0 : 2;
    const token = localStorage.getItem("tokenadmin");

    try {
      const response = await fetch(
        `${BaseUrl}api/products{itemId}&Status=${newStatus}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update item status");
      }

      setShowItems((prevState) => ({
        ...prevState,
        [itemId]: newStatus === 2,
      }));

      setChangesMade(true);
      toast.success("Item status updated successfully");
    } catch (error) {
      console.error("Error updating item status:", error);
      toast.error("Failed to update item status");
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setEditValues((prevRow) => ({
        ...prevRow,
        image: file,
      }));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("tokenadmin");
    if (token) {
      axios
        .get(
          BaseUrl + "api/products",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setproducts(res.data.products);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleDelete = async (itemId) => {
    const token = localStorage.getItem("tokenadmin");
    try {
      const response = await fetch(`${BaseUrl}api/product/${itemId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setItems((prevItems) => prevItems.filter((item) => item.Id !== itemId));
        toast.success("Product deleted successfully");
        fetchItems();
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete item: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item");
    }
  };

  const handleEdit = (itemId) => {
    const item = items.find((item) => item.id === itemId);
    console.log("unit_id", item);

    if (!item) {
      toast.error("Item not found");
      return;
    }
    setEditItem(itemId);
    setEditValues({
      Id: item.id || "",
      item_name: item.item_name || "",
      item_description: item.item_description || "",
      gst: item.gst || "",
      Cess: item.Cess || 0,
      hsn_code: item.hsn_code || "",
      BatchNum: item.BatchNum || 0,
      bar_code: item.bar_code || "",
      CAT_Number: item.CAT_Number || 0,
      item_code: item.item_code || "",
      serial_number: item.serial_number || "",
      manufacture_date: item.manufacture_date || "",
      expiry_date: item.expiry_date || "",
      rate: item.rate || "",
      unit_id: item.unit_id || "",
      item_type_id: item.item_type_id || "",
      warranty_period: item.warranty_period || "",
      model: item.model || "",
      image: "",
      is_popular: 1,
      stock_quantity: item.total_stock,

      // total_stock: item.total_stock || "",
    });
    setSelectedItemType(item.item_type_id || "");
  };
  const handleIncrementCounterSale = (itemId) => {
    setCounterSales((prevSales) => ({
      ...prevSales,
      [itemId]: (prevSales[itemId] || 0) + 1,
    }));
  };

  const handleDecrementCounterSale = (itemId) => {
    setCounterSales((prevSales) => ({
      ...prevSales,
      [itemId]: Math.max((prevSales[itemId] || 1) - 1, 0),
    }));
  };

  const renderItems = () => {
    console.log("items",items);
    
    const startIndex = (currentPage - 1) * displayCount;
    const endIndex = Math.min(startIndex + displayCount, items.length);
    const handleStockChange = (itemId, newStockValue) => {
      setUpdatedStockValues((prev) => ({
        ...prev,
        [itemId]: newStockValue, // Update the stock value for the specific item
      }));
    };

    return items?.map((item) => (
      <tr key={item.Id} className="border-t ">
        <td className="p-4">{item.SequentialId + 1}</td>
        <td className="p-4">
          {editItem === item.id ? (
            <input
              type="text" // Use number type for stock
              value={editValues.item_name} // Get the updated stock value or fallback to the original
              onChange={(e) =>
                setEditValues({ ...editValues, item_name: e.target.value })
              }
              className="w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
            />
          ) : (
            item.item_name // Display the total stock when not in edit mode
          )}
        </td>

        {/* <td className="p-4">{item.gst}</td> */}
        {/* <td className="p-4">{item.hsn_code}</td> */}
        <td className="p-4">
          {editItem === item.id ? (
            <input
              type="text"
              value={editValues.item_code}
              onChange={(e) =>
                setEditValues({ ...editValues, item_code: e.target.value })
              }
              className=" w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
            />
          ) : (
            item.item_code
          )}
        </td>
        {/* <td className="p-4">{item.serial_number}</td> */}
        <td className="p-4">
          {" "}
          {editItem === item.id ? (
            <input
              type="text"
              value={editValues.rate}
              onChange={(e) =>
                setEditValues({ ...editValues, rate: e.target.value })
              }
              className=" w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
            />
          ) : (
            item.rate
          )}
        </td>

        <td className="p-4">
        
         {editItem === item.id ? (
            <input
              type="text"
              value={editValues.stock_quantity}
              onChange={(e) =>
                setEditValues({ ...editValues, stock_quantity: e.target.value })
              }
              className=" w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:border-green-500 text-green-800"
            />
          ) : ( 
            item.total_stock
          )}
        </td>
        <td className="p-4">
          {editItem === item.id ? (
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              required
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
          ) : (
            <img
              src={`${BaseUrl}${item.image_url} `}
              alt="Item"
              className="w-12 h-12 object-cover"
            />
          )}
        </td>
        <td className="p-4">
  {editItem === item.id ? (
    <Switch
      checked={editValues.status === "active"}
      offColor="#888"
      onColor="#0f0"
      onChange={(checked) => {
        setEditValues({
          ...editValues,
          status: checked ? "active" : "inactive",
        });
      }}
    />
  ) : (
    <Switch
      checked={item.status === "active"}
      
      offColor="#888"
      onColor="#0f0"
     disabled
    />
  )}
</td>


        <td className="p-4 flex justify-center gap-4">
          {editItem === item.id ? (
            <FaRegCheckSquare
              onClick={() => {
                handleStockSubmit(item.id); // Save stock changes
                handleupdate(); // Call your update logic here
              }}
              className="cursor-pointer text-green-600"
            />
          ) : (
            <MdEdit
              onClick={() => handleEdit(item.id)}
              className="cursor-pointer text-blue-600"
            />
          )}
          <MdDeleteForever
            onClick={() => handleDelete(item.id)}
            className="cursor-pointer text-red-600"
          />
        </td>
      </tr>
    ));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlequantityChange = (e) => {
    const { value } = e.target;

    setcountersaledata((prevData) => ({
      ...prevData,
      items: [
        {
          ...prevData.items[0],
          quantity: value, // Update the quantity
        },
      ],
    }));
  };

  return (
    <div className="p-4">
      <div className="flex">
        <h2 className="text-2xl flex-1 flex justify-start font-bold mb-4">
          All Products{" "}
        </h2>
        <span className="justify-end flex flex-1">
          <button
            className="bg-green-600 text-white text-xl rounded p-1 mb-1"
            onClick={() => {
              setCountersale(true);
            }}>
            Counter Sale
          </button>
        </span>
      </div>
      <table className="min-w-full bg-white border-collapse  border border-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-4 text-left">S.No</th>
            <th className="p-4 text-left">Products Name</th>
            {/* <th className="p-4 text-left">GST</th> */}
            {/* <th className="p-4 text-left">HSN Code</th> */}
            <th className="p-4 text-left">Item Code</th>
            {/* <th className="p-4 text-left">Serial Number</th> */}
            <th className="p-4 text-left">Rate</th>
            <th className="p-4 text-center" style={{ color: "red" }}>
              Stock
            </th>
            <th className="p-4 text-left">Image</th>
            <th className="p-4 text-left">Status</th>
            <th className="p-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>{renderItems()}</tbody>
      </table>
      {Countersale && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-8 rounded-lg max-w-lg">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold">Add Counter Sale</p>
        <button
            onClick={() => {
              setCountersale(false); // Close the modal
              reset();               // Reset the data
            }}  
          className="text-gray-600 hover:text-gray-800">
          &times; {/* Close icon */}
        </button>
      </div>
      <div>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={countersaledata.name}
          required
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
      </div>
      <div>
        <select
          name="Products"
          value={countersaledata.items[0]?.product_id || ""}
          required
          onChange={handleProductChange}
          className="w-full p-2 border border-gray-300 rounded mb-2">
          <option value="">Select Product</option>
          {products?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.item_name}
            </option>
          ))}
        </select>
      </div>
      {/* <div>
        <select
          name="Unit"
          value={countersaledata.items[0]?.product_id || ""}
          required
          onChange={handleProductChange}
          className="w-full p-2 border border-gray-300 rounded mb-2">
          <option value="">Select The Units</option>
          {Units?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </div> */}
      <div>
        <input
          type="number"
          name="quantity" // Change to quantity
          placeholder="Quantity"
          required
          onChange={handlequantityChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
      </div>
      
      {/* <div>
        <input
          type="number"
          name="stock" // Add this input for stock editing
          placeholder="Stock"
          value={countersaledata.items[0]?.stock || ""} // Use stock value from the selected product
          required
          onChange={(e) => handleStockChange(e.target.value)} // Function to handle stock changes
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
      </div> */}
      <div className="flex justify-end">
        <button
          onClick={confirmcountersale}
          className={`bg-main text-white px-4 py-2 rounded-md mr-2 ${
            !countersaledata.name ||
            countersaledata.items.some(
              (item) => !item.product_id || !item.quantity// Check for valid stock
            )
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-subMain"
          }`}
          disabled={
            !countersaledata.name ||
            countersaledata.items.some(
              (item) => !item.product_id || !item.quantity// Disable button if invalid
            )
          }>
          Save
        </button>
      </div>
    </div>
  </div>
)}

{/* <ToastContainer /> */}


      {/* <ToastContainer /> */}
    </div>
  );
}

export default AllProductShow;
