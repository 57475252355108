import React, { useEffect, useState } from "react";
import MainModal from "./MainModal";
import { FiPlus, FiMinus } from "react-icons/fi";
import { FaRupeeSign } from "react-icons/fa";
import { BaseUrl } from "../../Config/config";
import Login from "./Login";
import toast from "react-hot-toast";

const ProductModal1 = ({ modalOpen, setModalOpen, product }) => {
  const [quantity, setQuantity] = useState(1); // Default quantity to 1
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const isOutOfStock = product?.Unit === 0;

  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => setToastMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [toastMessage]);

  const handleIncrement = () => {
    if (isOutOfStock) {
      setToastMessage("Item is out of stock");
      return;
    }

    if (quantity >= product?.Unit) {
      setToastMessage("Items are over");
    } else {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCart = async () => {
    if (isOutOfStock) {
      setToastMessage("Item is out of stock");
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      setLoginModalOpen(true);
      return;
    }

    const data = {
      product_id: product.id,
      quantity: quantity,
    };

    try {
      const response = await fetch(`${BaseUrl}api/cart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success("Item added to cart successfully");

        const cartItemKey = `cartItem_${product.Id}`;
        const existingItem = localStorage.getItem(cartItemKey);

        if (existingItem) {
          const existingItemDetails = JSON.parse(existingItem);
          existingItemDetails.Quantity += quantity;
          localStorage.setItem(cartItemKey, JSON.stringify(existingItemDetails));
        } else {
          const newItem = {
            Id: product.Id,
            ItemName: product.ItemName,
            ItemImage: product.ItemImage,
            Rate: product.Rate,
            Quantity: quantity,
            ItemDescription: product.ItemDescription,
            GST: product.GST,
            ItemType: product.ItemType,
            flashSalePrice: product.flashSalePrice,
            tag: product.tag,
          };
          localStorage.setItem(cartItemKey, JSON.stringify(newItem));
        }

        setModalOpen(false);
      } else {
        throw new Error("Failed to add item to cart");
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      setToastMessage("Failed to add item to cart");
    }
  };

  return (
    <>
      <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className="relative inline-block lg:py-0 py-24 md:w-3/4 w-full h-full max-h-screen overflow-y-auto align-middle transition-all transform">
          <div className="grid lg:grid-cols-2 gap-4 bg-white shadow-lg rounded-lg p-6">
            <div className="flex items-center justify-center h-80 overflow-hidden rounded-lg shadow-lg">
              <img
                src={BaseUrl + `${product.image_url}`}
                className="w-full h-full object-cover"
                alt={product.ItemName}
              />
            </div>
            <div className="flex flex-col p-6 bg-white border-2 border-green-600 rounded-lg shadow-lg">
              <h1 className="text-2xl font-bold text-gray-800 mb-2">{product.ItemName}</h1>
              <p className="text-gray-600 mb-4">{product.ItemDescription}</p>
              <div className="flex items-center mb-4">
                {product.flashSale ? (
                  <h2 className="text-2xl font-bold text-gray-800 flex items-center">
                    <FaRupeeSign className="mr-1 text-xl" />
                    {product.flashSalePrice}
                    <del className="text-gray-500 ml-4 text-lg flex items-center">
                      <FaRupeeSign className="mr-1 text-lg" />
                      {product.Rate}
                    </del>
                  </h2>
                ) : (
                  <div className="text-2xl font-bold text-gray-800 flex items-center">
                    <FaRupeeSign className="mr-1 text-xl" />
                    {product.Rate}
                  </div>
                )}
              </div>
              {isOutOfStock ? (
                <p className="text-red-600 font-semibold mb-4">Currently Unavailable</p>
              ) : (
                <>
                  <div className="flex items-center gap-4 mb-4">
                    <button
                      onClick={handleDecrement}
                      disabled={quantity === 1}
                      className="py-2 px-4 border-2 border-gray-800 bg-gray-100 text-gray-800 rounded-md shadow-md hover:bg-gray-200 transition-all duration-300"
                    >
                      <FiMinus size={20} />
                    </button>
                    <p className="text-lg font-semibold">{quantity}</p>
                    <button
                      onClick={handleIncrement}
                      disabled={product.Unit < quantity}
                      className="py-2 px-4 border-2 border-gray-800 bg-gray-100 text-gray-800 rounded-md shadow-md hover:bg-gray-200 transition-all duration-300"
                    >
                      <FiPlus size={20} />
                    </button>
                  </div>
                  {product.Unit - quantity < 10 && (
                    <p className="text-red-600 font-semibold mb-4">
                      Only {product.Unit - quantity} units left in stock!
                    </p>
                  )}
                  <button
                    className="w-full py-3 px-4 border-2 border-green-600 bg-green-600 text-white font-bold rounded-md shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 transition-all duration-300"
                    onClick={handleAddToCart}
                    disabled={isOutOfStock}
                  >
                    Add to Cart
                  </button>
                </>
              )}
              <div className="mt-4 text-gray-600">
                <div className="text-sm font-semibold">Category:</div>
                <span className="text-gray-800 font-bold">{product.ItemType}</span>
              </div>
              <div className="mt-4 flex flex-wrap gap-2">
                {product.tag &&
                  product.tag.map((t, i) => (
                    <div
                      key={i}
                      className="py-1 px-3 bg-gray-800 text-white rounded-full text-xs"
                    >
                      {t}
                    </div>
                  ))}
              </div>
              <div className="mt-4 text-gray-600">
                <p className="text-sm">GST: {product.GST}</p>
                <p className="text-sm">Item Type: {product.ItemType}</p>
              </div>
            </div>
          </div>

          {toastMessage && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-red-600 text-white p-4 rounded-md shadow-lg text-lg font-semibold">
                {toastMessage}
              </div>
            </div>
          )}
        </div>
      </MainModal>

      <Login modalOpen={loginModalOpen} setModalOpen={setLoginModalOpen} />
    </>
  );
};

export default React.memo(ProductModal1);
