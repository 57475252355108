import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { BaseUrl } from '../../Config/config';
import { saveToLocalStorage } from '../../lib/localstorage';
import { getFromLocalStorage } from '../../lib/localstorage';

const OtpModal = ({ isOpen, onClose, email }) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [isResending, setIsResending] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [cooldownTimer, setCooldownTimer] = useState(null);

  const navigate = useNavigate(); // Initialize navigate

  // Handles OTP submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BaseUrl}api/email/verify-otp`, {
        email,
        otp
      });

      if (response.status === 200) {
        const token = response.data.token; // Assuming token is in response.data.token

        if (token) {
          localStorage.setItem('token', token); // Store token in local storage
          saveToLocalStorage('pathrodo', {email, token})
          toast.success('OTP verified successfully!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: true,
          });
          onClose(); // Close the modal
          navigate('/'); // Navigate to the home screen ("/" route)
        } else {
          throw new Error('Token not received');
        }
      } else {
        throw new Error(response.data.message || 'Verification failed');
      }
    } catch (err) {
      setError(err.message);
      toast.error(`Error: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  // Handles resending the OTP
  const handleResendOtp = async () => {
    setIsResending(true);
    try {
      const response = await axios.post(`${BaseUrl}api/email/resend-otp`, {
        email
      });

      if (response.status === 200) {
        toast.success('OTP resent successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else if (response.status === 429) {
        handleCooldown();
        toast.error('Too many attempts. Please try again in 15 minutes.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        throw new Error(response.data.message || 'Failed to resend OTP');
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } finally {
      setIsResending(false);
    }
  };

  // Handle cooldown for 15 minutes
  const handleCooldown = () => {
    setResendDisabled(true);
    const timer = setTimeout(() => {
      setResendDisabled(false);
    }, 900000); // 15 minutes
    setCooldownTimer(timer);
  };

  // Cleanup timer on component unmount
  useEffect(() => {
    return () => {
      if (cooldownTimer) {
        clearTimeout(cooldownTimer);
      }
    };
  }, [cooldownTimer]);

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-80 relative">
          <h3 className="text-xl font-semibold mb-4">Enter OTP</h3>
          <form onSubmit={handleOtpSubmit}>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              maxLength="8"
              className="border border-gray-300 p-2 w-full rounded mb-4"
            />
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button type="submit" className="bg-green-800 text-white px-4 py-2 rounded w-full mb-2">
              Verify OTP & Login
            </button>
          </form>
          <button
            onClick={handleResendOtp}
            disabled={isResending || resendDisabled}
            className={`${resendDisabled ? 'bg-gray-400' : 'bg-blue-500'} text-white px-4 py-2 rounded w-full`}
          >
            {isResending ? 'Resending...' : resendDisabled ? 'Try again later' : 'Resend OTP'}
          </button>
          <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 text-xl">
            &times;
          </button>
        </div>
      </div>
    )
  );
};

export default OtpModal;
