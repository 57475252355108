import { atom } from 'recoil';

// This atom will track changes in the cart (add/remove actions)
export const cartUpdateState = atom({
  key: 'cartUpdateState', // unique ID (with respect to other atoms/selectors)
  default: 0, // initial value
});

export const cartcount = atom({
  key: 'cartcount', // unique ID (with respect to other atoms/selectors)
  default: 0, // initial value
});
