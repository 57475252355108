import React from 'react';
import Filter from '../components/Filter';
import Products from '../components/Products';
import Layout from '../layout/Layout';
import { ProductsData } from '../Data/ProductsData';
import Head from './../components/Head';
import { useParams } from 'react-router-dom';

function CategoryPage() {
  let { category } = useParams();

  const Datas = ProductsData.filter((val) => val.type === category);

  return (
    <Layout>
      <div className="min-h-screen container mx-auto px-4 sm:px-6 my-6">
        <Head title={category} />
        <Filter total={Datas?.length} />

        {/* Slider Component */}
        <div className="flex flex-col items-center my-6">
          <input
            type="range"
            min="1"
            max="100"
            step="1"
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            style={{
              background: 'linear-gradient(to right, #000 0%, #000 50%, #ccc 50%, #ccc 100%)',
            }}
          />
        </div>

        <div className="grid gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
          {Datas.map((p) => (
            <Products bg={true} key={p._id} product={p} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default CategoryPage;
